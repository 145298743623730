import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./template-4.css";
import { ApiUrl, OrderHubUrl, WaiterHubUrl } from "../../../../consts/api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../react-reduxs/configure_store";
import { getCompany } from "../../../../react-reduxs/slices/companies/company_slice";
import { useTranslation } from "react-i18next";
import { getCompanyId } from "../../../../react-reduxs/slices/auth/auth_slice";
import { getTableId } from "../../../../react-reduxs/slices/tables/tableSlice";
import * as signalR from "@microsoft/signalr";
import { Tooltip, Popconfirm, Space, Avatar } from "antd";
import BasketComponent from "../../../../components/baskets/BasketComponent";
import { I18nPagePropertyEnum } from "../../../../i18n/i18n_page_property";
import CompanyTableOrderPage from "../../tables/CompanyTableOrderPage";
import MenuProductServcie from "../../../../services/menu_products/menu_product.service";
import MenuCategoryService from "../../../../services/menu_categories/menu_category.service";
import { setCategoryList } from "../../../../react-reduxs/slices/categories/category_slice";
import {
  getProductSelectedCategoryId,
  setProductList,
} from "../../../../react-reduxs/slices/products/product_slice";
import Template4ProductList from "./Template4ProductList";
import Template4Category from "./Template4Category";
import { setOrder } from "../../../../react-reduxs/slices/orders/order_slice";
import { toast } from "react-toastify";
import { OrderHubEnum } from "../../../../enums/order_hub.enum";
import { OrderService } from "../../../../services/orders/order.service";

const Template4 = () => {
  const [count, setCount] = useState<number>(0);

  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const tableId = useSelector(getTableId);
  const selectCategoryId = useSelector(getProductSelectedCategoryId);

  const orderService = useMemo(() => new OrderService(), []);
  const categoryService = useMemo(() => new MenuCategoryService(), []);
  const productService = useMemo(() => new MenuProductServcie(), []);
  const [tooltipVisble] = useState<boolean>(false);

  const getOrder = useCallback(async () => {
    try {
      let orderResponse = await orderService.getOrder(companyId, tableId ?? "");

      if (orderResponse) {
        dispatch(setOrder(orderResponse));
      } else {
        dispatch(setOrder({} as any));
      }
      console.error(orderResponse);
    } catch (error) {
      console.error("OrderService Qery", error);
    }
  }, [companyId, dispatch, orderService, tableId]);

  const getOrderHup = useCallback(
    async (companyId: number) => {
      if (companyId !== 0) {
        let connection = new signalR.HubConnectionBuilder()
          .withUrl(OrderHubUrl(companyId).url)
          .withAutomaticReconnect()
          .build();

        const startConnection = async () => {
          try {
            await connection.start();
            await getOrder();
            console.log("SignalR bağlantısı kuruldu.");
          } catch (error) {
            console.error(
              "SignalR bağlantısı başlatılırken hata oluştu:",
              error
            );
            // Bağlantı başarısız olduğunda tekrar denemek için bir süre bekleyebilirsiniz
            toast.error("Bağlantı Koptu lütfen sayfanızı yenileyin");
          }
          connection.onclose(() => {
            // Bağlantı kapandığında tekrar bağlanmaya çalış
            console.log(
              "SignalR bağlantısı kapandı. Yeniden bağlanmaya çalışılıyor..."
            );
            toast.error("Bağlantı Koptu lütfen sayfanızı yenileyin");
          });
          connection.on(OrderHubEnum.receiveOrder, (order) => {
            if (order.order.tableId === tableId) {
              dispatch(setOrder(order));
            }
            // Yeni siparişi işlemek için istediğiniz işlemleri yapabilirsiniz
          });
          connection.on(OrderHubEnum.removeOrderTable, (tableIdParams) => {
            if (tableIdParams === tableId) {
              dispatch(setOrder({} as any));
            }
          });
        };
        startConnection();
      }

      // Bağlantıyı başlat
    },
    [dispatch, getOrder, tableId]
  );

  const init = useCallback(async () => {
    let response = await categoryService.getListByStatus(companyId);
    let productResponse = await productService.getListByStatus(companyId);
    await getOrderHup(companyId);
    if (response) {
      dispatch(setCategoryList(response));
    } else {
      dispatch(setCategoryList([]));
    }
    if (productResponse) {
      dispatch(setProductList(productResponse));
    } else {
      dispatch(setProductList([]));
    }
  }, [categoryService, productService, dispatch, companyId, getOrderHup]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        init();
      }
      return 1;
    });
    return () => {};
  }, [init]);

  return (
    <div>
      <div className="t4-head">
        <div className="t4-header">
          <img
            alt=""
            width={150}
            src={
              company?.logo
                ? `${ApiUrl}/${company.logo}`
                : "/images/category.png"
            }
          />
          <div className="t4-header-content">
            <h3 className="t4-header-title">{company?.title}</h3>
            {company?.orderAtTheTable === true ||
            company?.waiterCall === true ? (
              <h6 className="t4-header-tableName"> {company?.tableName}</h6>
            ) : (
              <></>
            )}
          </div>
        </div>
        {selectCategoryId === 0 ? <Template4Category /> : <div></div>}
      </div>
      {selectCategoryId > 0 ? (
        <div>
          <Template4ProductList />
          {company?.orderAtTheTable === true || company?.waiterCall === true ? (
            <div
              style={{
                position: "fixed",
                zIndex: 10000000,
                top: "77%",
                right: 11,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {company.orderAtTheTable === true ? (
                <Tooltip
                  open={tooltipVisble}
                  placement="left"
                  title={t(I18nPagePropertyEnum.templateBasketFloat)}
                >
                  <BasketComponent color="#549e83" />
                </Tooltip>
              ) : (
                <></>
              )}
              {company?.waiterCall === true ? (
                <Tooltip
                  open={tooltipVisble}
                  placement="left"
                  title={t(I18nPagePropertyEnum.templateWaiterFloat)}
                >
                  <Popconfirm
                    icon={
                      <i
                        style={{ color: "black", paddingRight: 5 }}
                        className="fa-duotone fa-user-chef fa-lg"
                      ></i>
                    }
                    placement="leftTop"
                    title={t(I18nPagePropertyEnum.menuProductWaiter)}
                    description={t(
                      I18nPagePropertyEnum.menuProductCallTheWaiter
                    )}
                    okText={"Evet"}
                    cancelText={t(I18nPagePropertyEnum.commonCancel)}
                    onConfirm={async () => {
                      console.error(companyId, tableId);

                      const connection = new signalR.HubConnectionBuilder()
                        .withUrl(WaiterHubUrl(companyId).url)
                        .withAutomaticReconnect()
                        .configureLogging(signalR.LogLevel.Information)
                        .build();

                      try {
                        await connection
                          .start()
                          .then(() => {
                            console.log("SignalR bağlantısı kuruldu.WaiterHub");
                          })
                          .catch((error) => {
                            console.error(
                              "SignalR bağlantısı başlatılırken hata oluştu:",
                              error
                            );
                          });
                        await connection.invoke(
                          "CallTheWaiter",
                          companyId,
                          tableId
                        );
                      } catch (error) {
                      } finally {
                        connection.stop();
                      }
                    }}
                  >
                    <Space
                      style={{
                        cursor: "pointer",

                        margin: 5,
                      }}
                    >
                      <Avatar
                        style={{ backgroundColor: "white" }}
                        shape="square"
                        icon={
                          <i
                            style={{ color: "#549e83" }}
                            className="fa-duotone fa-user-chef fa-lg"
                          ></i>
                        }
                      />
                    </Space>
                  </Popconfirm>
                </Tooltip>
              ) : (
                <></>
              )}
              {company.orderAtTheTable === true ? (
                <Tooltip
                  open={tooltipVisble}
                  placement="left"
                  title={t(I18nPagePropertyEnum.templateTableFloat)}
                >
                  <CompanyTableOrderPage color="#549e83" />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Template4;
