import { FilterMatchMode } from "primereact/api";
import { DataTableFilterMeta } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

import React, { useState } from "react";

type ThemeDataTableSearchProps = {
  filters?: DataTableFilterMeta;
  inputPlaceholder?: string;
  setFilters: React.Dispatch<React.SetStateAction<DataTableFilterMeta>>;
};
const ThemeDataTableSearch: React.FC<ThemeDataTableSearchProps> = ({
  filters,
  setFilters,
  inputPlaceholder = "Search",
}) => {
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Filtreyi güncelle
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));

    setGlobalFilterValue(value);
  };
  return (
    <div className="flex justify-content-end">
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder={inputPlaceholder}
        />
      </IconField>
    </div>
  );
};

export default ThemeDataTableSearch;
