import { Checkbox, CheckboxProps } from "primereact/checkbox";
import React from "react";
import "./checkbox.css";
interface ThemeCheckboxProps extends CheckboxProps {
  label?: string;
  htmlFor?: string;
}

const ThemeCheckbox: React.FC<ThemeCheckboxProps> = ({
  label="",
  htmlFor="_",
  ...rest
}) => {
  return (
    <div className="chexbox-container">
      <Checkbox {...rest} />
      <label htmlFor={htmlFor}>{label}</label>
    </div>
  );
};

export default ThemeCheckbox;
