import BaseApiService from "../../services/base_api.service";
import { NotificationSendDto } from "./models/notification-send.dto";
import { NotificationSettingModel } from "./models/notification-setting.model";

class NotificationSettingServcie extends BaseApiService {
  constructor() {
    super("NotificationSettings");
  }

  add(
    notification: NotificationSettingModel
  ): Promise<NotificationSettingModel | undefined> {
    return this.post<NotificationSettingModel>(``, notification);
  }

  update(
    notification: NotificationSettingModel
  ): Promise<NotificationSettingModel | undefined> {
    return this.post<NotificationSettingModel>(``, notification);
  }
  sendCompany(notificationSend:NotificationSendDto):Promise<boolean|undefined>{
    console.error(notificationSend);
    
    return this.post<boolean>(`SendNotification`,{
      "companyId": 5,
      "title": "string",
      "body": "string",
      "image": "https://apimenu.qrmenuscan.com/restaurant2.png"
    });
  }
  getByUserId(userId: number): Promise<NotificationSettingModel | undefined> {
    return this.get<NotificationSettingModel>(
      `GetNotificationSettingByUserId?userId=${userId}`
    );
  }
}
export default NotificationSettingServcie;
