import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { toast } from "react-toastify";
import CompManagerMenuCategoryAdd from "./CompManagerMenuCategoryAdd";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import {
  ColumnType,
  ThemeDataTable,
  ThemeDataTableSearch,
} from "@theme/ui-components/data-tables";
import { DataTableFilterMeta } from "primereact/datatable";
import ThemeDeleteButton from "@theme/ui-components/buttons/ThemeDeleteButton";
import { transactionDate } from "../../../funcs/func";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";
import ThemeToggleButton from "@theme/ui-components/toggle-buttons/ThemeToggleButton";

const CompanyManagerMenuCategoryList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);

  const [filters, setFilters] = useState<DataTableFilterMeta>({});
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const companyId = useSelector(getCompanyId);
  const menuCategoryService = useMemo(() => new MenuCategoryService(), []);
  const fileUploadService = useMemo(() => new FileUploadService(), []);
  const categoryModel = useRef<MenuCategoryModel>();
  const tempCategoryList = useRef<MenuCategoryModel[]>([]);

  const getCategory = useCallback(async () => {
    let response = await menuCategoryService.getList(companyId);

    if (response) {
      setCategoryList(response);
      tempCategoryList.current = response;
    }
  }, [companyId, menuCategoryService]);
  
  const init = useCallback(async () => {
    console.info(count);
    setIsLoading(true);
    await getCategory();
    setIsLoading(false);
  }, [getCategory]);

  useEffect(() => {
    setCount((c) => {
      if (c === 0) {
        init();
      }
      return 1;
    });

    return () => {};
  }, [init]);

  const saveCategory = useCallback(
    async (menuCategoryModel: MenuCategoryModel) => {
      setIsLoading(true);
      if (menuCategoryModel.id === 0 || menuCategoryModel.id === undefined) {
        let response = await menuCategoryService.add(menuCategoryModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");

          await getCategory();
        }
      } else {
        let response = await menuCategoryService.update(menuCategoryModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");

          await getCategory();
        }
      }
      setIsLoading(false);
    },
    [getCategory, menuCategoryService]
  );

  const tablePhoto = (data: MenuCategoryModel) => {
    const src = data.photo?.toApiImage();

    return <img width={70} height={70} src={src} alt="" />;
  };
  const tableActions = (data: MenuCategoryModel) => {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <ThemeButton
          loading={isLoading}
          disabled={isLoading}
          severity="success"
          type="button"
          onClick={() => {
            categoryModel.current = data;
            setModalVisible(true);
          }}
          icon={<i className="fa-duotone fa-pen-to-square"></i>}
        />
        <ThemeDeleteButton
          accept={async () => {
            setIsLoading(true);
            let response = await menuCategoryService.deleteCategory(data.id);
            if (response) {
              if (data.photo !== undefined && data.photo !== "") {
                await fileUploadService.deleteFile(data.photo);
              }
              toast.success("Silme başarılı");
            }
            await getCategory();
            setIsLoading(false);
          }}
          reject={async () => {
            setIsLoading(false);
            return Promise.resolve();
          }}
        />
      </div>
    );
  };
  const tableStatus = (data: MenuCategoryModel) => {
    return (
      <ThemeToggleButton
        onLabel="Aktif"
        offLabel="Pasif"
        disabled={true}
        checked={data.status}
      />
    );
  };
  const columnsT: ColumnType[] = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "photo",
      header: "Fotoğraf",
      body: tablePhoto,
    },
    {
      header: "Ad",
      field: "name",
    },

    {
      header: "Durum",
      field: "status",
      body: tableStatus,
    },
    {
      field: "transactionDate",
      header: "İşlem Zamanı",
      body: transactionDate,
    },
    {
      field: "actions",
      header: "",
      body: tableActions,
    },
  ];
  return (
    <div>
      <Title level={3} className="my-title">
        Ürün Kategori Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <ThemeButton
            loading={isLoading}
            onClick={() => {
              setModalVisible(true);
              categoryModel.current = undefined;
            }}
            label="Yeni Kategori Ekle"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          />
        </Col>
        <Col>
          <ThemeDataTableSearch filters={filters} setFilters={setFilters} />
        </Col>
      </Row>

      <ThemeDataTable
        value={categoryList}
        filters={filters}
        columns={columnsT}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
      {modalVisible ? (
        <ThemeDialog
          header=""
          visible={modalVisible}
          onHide={() => {
            setModalVisible(false);
          }}
        >
          <CompManagerMenuCategoryAdd
            isLoading={isLoading}
            saveCategory={saveCategory}
            categoryModel={categoryModel.current}
            modalClose={() => {
              setModalVisible(false);
            }}
          />
        </ThemeDialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyManagerMenuCategoryList;
