import { ToggleButton, ToggleButtonProps } from "primereact/togglebutton";
import React from "react";

interface ThemeToggleButtonProps extends ToggleButtonProps {}

const ThemeToggleButton: React.FC<ThemeToggleButtonProps> = ({ ...rest }) => {
  return <ToggleButton {...rest} />;
};

export default ThemeToggleButton;
