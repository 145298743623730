import { Button, Form, InputNumber, message } from "antd";
import React, { useCallback, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import * as signalR from "@microsoft/signalr";
import { MenuProductModel } from "../../../../services/menu_products/models/menu_product.model";
import { OrderHubUrl } from "../../../../consts/api";
import { getCompany } from "../../../../react-reduxs/slices/companies/company_slice";
import {
  OrderProductStatusEnum,
  OrderStatusEnum,
} from "../../../../enums/orders/order_status.enum";
import { useSelector } from "react-redux";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";

type WaiterProductAddToBasketProps = {
  tableId: string;
  product: MenuProductModel;
};

const WaiterProductAddToBasket: React.FC<WaiterProductAddToBasketProps> = ({
  product,
  tableId,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const company = useSelector(getCompany);
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(OrderHubUrl(company?.id ?? 0).url)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const addToBasket = useCallback(async () => {
    const amount = form.getFieldValue("amount");
    await connection
      .start()
      .then(() => {
        console.log("SignalR bağlantısı kuruldu.");
      })
      .catch((err) => {
        console.error(err.toString());
        connection.stop();
      });
    try {
      await connection.invoke(
        "PlaceOrder",
        {
          tableId: tableId,
          currencyId: company?.currencyId,
          companyId: company?.id,
          mobilePhone: "",
          status: OrderStatusEnum.paymentNotMade,
        },
        [
          {
            amount: amount,
            menuProductId: product.id,
            menuProductName: product.title,
            menuProductPhoto: product.photo ?? "",
            price: product.price,
            status: OrderProductStatusEnum.approved,
            orderNote: form.getFieldValue("explanation"),
          },
        ]
      );
      form.resetFields();
      messageApi.open({
        type: "success",
        content: `İşlem başarılı.`,
      });
    } catch {
    } finally {
      connection.stop();
    }
  }, []);

  const onPlus = useCallback(() => {
    form.setFieldValue("amount", form.getFieldValue("amount") + 1);
  }, []);
  const onMinus = useCallback(() => {
    const amount = form.getFieldValue("amount");
    if (amount > 1) {
      form.setFieldValue("amount", form.getFieldValue("amount") - 1);
    }
  }, []);
  return (
    <div>
      {contextHolder}
      <ThemeButton
        icon="pi pi-cart-plus"
        className="p-button-rounded"
        onClick={() => {
          setModalVisible(true);
        }}
      />
      <ThemeDialog
        header="Siparişi Ekle"
        visible={modalVisible}
        onHide={() => setModalVisible(false)}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="amount" label="Miktar" initialValue={1}>
            <InputNumber
              addonBefore={
                <Button
                  onClick={onPlus}
                  icon={<PlusOutlined />}
                  variant="link"
                  color="primary"
                />
              }
              addonAfter={
                <Button
                  onClick={onMinus}
                  icon={<MinusOutlined />}
                  variant="link"
                  color="danger"
                />
              }
            />
          </Form.Item>
          <Form.Item name="explanation" label="Açıklama">
            <TextArea rows={3} />
          </Form.Item>
        </Form>
        <ThemeButton label="Ekle" onClick={addToBasket} />
      </ThemeDialog>
    </div>
  );
};

export default WaiterProductAddToBasket;
