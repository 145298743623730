import React, { forwardRef } from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { FormFieldType } from "./form-field-type";

interface ThemeDynamicFormProps {
  fields: FormFieldType[];
  fieldClassName?: string;
  initialValues: Record<string, any>;
  onFinish: (
    values: any,
    formikHelpers: FormikHelpers<Record<string, any>>
  ) => void;
}

const ThemeDynamicForm = forwardRef<any, ThemeDynamicFormProps>(
  (
    {
      fields,
      initialValues,
      onFinish,
      fieldClassName = "field col-12 md:col-6 lg:col-4",
    },
    ref
  ) => {
    const validationSchema = Yup.object().shape(
      fields.reduce<Record<string, Yup.AnySchema>>((acc, field) => {
        if (field.validation) {
          acc[field.name] = field.validation;
        }
        return acc;
      }, {})
    );

    return (
      <div>
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFinish}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="formgrid grid">
                {fields.map((field) => {
                  return (
                    <div key={field.name} className={fieldClassName}>
                      <label htmlFor={field.name}>{field.label}</label>
                      {field.chield(
                        values,
                        handleChange,
                        handleBlur,
                        field.name
                      )}
                      <small id={field.name + "help"}>
                        {errors[field.name] && touched[field.name] && (
                          <div>{errors[field.name]?.toString()}</div>
                        )}
                      </small>
                    </div>
                  );
                })}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
);

export default ThemeDynamicForm;
