import { Button, ButtonProps } from "primereact/button";

import React, { forwardRef } from "react";
import "./th-button.css";

interface ThemeButtonProps extends ButtonProps  {}
const ThemeButton = forwardRef<HTMLButtonElement, ThemeButtonProps>(
  ({ outlined = true, ...rest }, ref) => {
    return <Button ref={ref as React.LegacyRef<Button> } outlined={outlined} {...rest} />;
  }
);

export default ThemeButton;
