import ThemeCard from "@theme/ui-components/cards/ThemeCard";
import { Chart } from "primereact/chart";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChartService } from "../../../services/charts/chart.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import dayjs from "dayjs";
import { Calendar } from "primereact/calendar";
import { generateRandomColor } from "../../../funcs/func";
import ThemeLoading from "@theme/ui-components/loadings/ThemeLoading";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";

const OrderChart = () => {
  const [date, setDate] = useState(dayjs().toDate());
  const chartService = useMemo(() => new ChartService(), []);
  const companyId = useSelector(getCompanyId);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const getOrderList = useCallback(
    async (date: Date) => {
      setLoading(true);
      const uniqueColors = new Set();
      const backgroundColors: any[] = [];
      const hoverBackgroundColors: any[] = [];

      const orderResponse = await chartService.getOrderChartList(
        companyId,
        dayjs(date).format("YYYY-MM-DD")
      );
      const labels = orderResponse.map((x) => x.label);
      const values = orderResponse.map((x) => x.value);
      labels.forEach(() => {
        let color = generateRandomColor();
        while (uniqueColors.has(color)) {
          color = generateRandomColor();
        }
        uniqueColors.add(color);
        backgroundColors.push(color);
        // Hover rengi için aynı rengin daha açık bir tonunu oluştur
        let hoverColor = generateRandomColor();
        while (uniqueColors.has(hoverColor)) {
          hoverColor = generateRandomColor();
        }
        uniqueColors.add(hoverColor);
        hoverBackgroundColors.push(hoverColor);
      });
      setChartData({
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
          },
        ],
      });
      const options = {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      };
      setChartOptions(options);
      setLoading(false);
    },
    [companyId, chartService]
  );
  useEffect(() => {
    getOrderList(date);
    const interval = setInterval(() => {
      getOrderList(date);
    }, 60000);
    return () => clearInterval(interval);
  }, [getOrderList, date]);

  const header = () => {
    return (
      <div className="grid w-full p-1">
        <div className="col">
          <Calendar
            value={date}
            dateFormat="dd/mm/yy"
            onChange={(e) => {
              setDate(e.value ?? dayjs().toDate());
              getOrderList(dayjs(e.value).toDate());
            }}
            showIcon
          />
        </div>
        <div className="col  font-bold">
          <p>Gün İçi Sipariş Grafiği</p>
        </div>
        <div className="col-1">
          <ThemeButton
            onClick={() => getOrderList(date)}
            icon="pi pi-refresh"
          />
        </div>
      </div>
    );
  };
  return (
    <ThemeCard className="w-full" header={header()}>
      <ThemeLoading loading={loading}>
        <Chart
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="w-full md:w-30rem"
        />
      </ThemeLoading>
    </ThemeCard>
  );
};

export default OrderChart;
