import * as signalR from "@microsoft/signalr";
import { message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import { OrderHubUrl } from "../../../../../consts/api";
import {
  OrderStatusEnum,
  OrderProductStatusEnum,
} from "../../../../../enums/orders/order_status.enum";
import { getCompany } from "../../../../../react-reduxs/slices/companies/company_slice";
import { MenuProductAmountType } from "../../../../../models/menu_product_amount_type";
import { MenuProductModel } from "../../../../../services/menu_products/models/menu_product.model";
import { I18nPagePropertyEnum } from "../../../../../i18n/i18n_page_property";
import ThemeConfirmPopupContent from "@theme/ui-components/dialogs/ThemeConfirmPopupContent";
import { InputTextarea } from "primereact/inputtextarea";
import ThemeConfirmPopup from "@theme/ui-components/dialogs/ThemeConfirmPopup";

interface T4OrderAddBtnComponentProps {
  menuProductAmountList: MenuProductAmountType[];
  menuProduct: MenuProductModel;
  tableId: string;
  removeMenuProductAmount: any;
  title?: string;
}
const T4OrderAddBtnComponent: React.FC<T4OrderAddBtnComponentProps> = ({
  menuProductAmountList = [],
  menuProduct,
  tableId,
  removeMenuProductAmount,
  title = "Sipariş Ver",
}) => {
  const company = useSelector(getCompany);
  const { t } = useTranslation();
  const buttonEl = useRef(null);
  const [orderNote, setOrderNote] = useState("");
  const [orderPopConfirmVisible, setOrderPopConfirmVisible] =
    useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(OrderHubUrl(company?.id ?? 0).url)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  const senOrder = useCallback(async () => {
    let amount =
      menuProductAmountList.find((x) => x.menuProductId === menuProduct.id)
        ?.amount ?? 1;
    await connection
      .start()
      .then(() => {
        console.log("SignalR bağlantısı kuruldu.");
      })
      .catch((err) => {
        console.error(err.toString());
        connection.stop();
      });
    try {
      await connection.invoke(
        "PlaceOrder",
        {
          tableId: tableId,
          currencyId: company?.currencyId,
          companyId: company?.id,
          mobilePhone: "",
          status: OrderStatusEnum.paymentNotMade,
        },
        [
          {
            amount: amount,
            menuProductId: menuProduct.id,
            menuProductName: menuProduct.title,
            menuProductPhoto: menuProduct.photo,
            menuProductPrice: menuProduct.price,
            status: OrderProductStatusEnum.pendingApproval,
            orderNote: orderNote,
          },
        ]
      );
      removeMenuProductAmount(menuProduct.id);
      messageApi.open({
        type: "success",
        content: `${amount} Sipariş alındı.`,
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: `Sipariş Gönderilemedi ${error}`,
      });
    } finally {
      connection.stop();
      setOrderPopConfirmVisible(false);
    }
  }, [orderNote,company,connection,menuProduct,menuProductAmountList,messageApi,removeMenuProductAmount,tableId]);
  const content = (acceptBtnRef: any, rejectBtnRef: any, hide: () => void) => {
    return (
      <ThemeConfirmPopupContent
        acceptBtnLabel={t(I18nPagePropertyEnum.commonOk)}
        acceptBtnRef={acceptBtnRef}
        accept={() => {
          senOrder();
          setOrderPopConfirmVisible(false);
          setOrderNote("");
          hide();
        }}
        rejectBtnLabel={t(I18nPagePropertyEnum.commonCancel)}
        rejectBtnRef={rejectBtnRef}
        reject={() => {
          setOrderNote("");
          setOrderPopConfirmVisible(false);
          hide();
        }}
        header={<p>{"Sipariş Ver "}</p>}
        headerLIcon={
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fa-duotone fa-bell-concierge"
          />
        }
      >
        <label>{t(I18nPagePropertyEnum.menuProductOrderNote)}</label>
        <InputTextarea
          value={orderNote}
          onChange={(e) => {
            setOrderNote(e.target.value);
          }}
          rows={2}
        />
      </ThemeConfirmPopupContent>
    );
  };
  return (
    <div>
      {contextHolder}
      {company?.orderAtTheTable ? (
        <button
          ref={buttonEl}
          className="btn"
          onClick={() => {
            setOrderPopConfirmVisible(true);
          }}
        >
          <FaPaperPlane /> {title}
        </button>
      ) : (
        <></>
      )}

      <ThemeConfirmPopup
        target={buttonEl.current!}
        visible={orderPopConfirmVisible}
        onHide={() => {
          setOrderPopConfirmVisible(false);
        }}
        content={({ acceptBtnRef, rejectBtnRef, hide }) =>
          content(acceptBtnRef, rejectBtnRef, hide)
        }
      />
    </div>
  );
};

export default T4OrderAddBtnComponent;
