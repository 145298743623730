import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "./react-reduxs/configure_store";
import I18nConfigureProvicer from "./i18n/I18nConfigureProvicer";
import SignalRContext from "./signaIr/SignalRContext";

import "primereact/resources/themes/lara-light-cyan/theme.css";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primeflex/themes/primeone-light.css";
import { PrimeReactProvider } from "primereact/api";
import "core-js/stable";
import "regenerator-runtime/runtime";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then((registration) => {
      console.log("Service Worker kaydedildi:", registration);
    })
    .catch((error) => {
      console.error("Service Worker kaydedilemedi:", error);
    });
}

root.render(
  <PrimeReactProvider>
    <I18nConfigureProvicer>
      <Provider store={store}>
        <SignalRContext>
          <ConfigProvider
            theme={{
              components: {
                Typography: {
                  colorText: "white",
                },
                Tabs: {
                  colorText: "lightgray",
                  itemActiveColor: "#ffffff",
                  itemSelectedColor: "#ffffff",
                  inkBarColor: "#ffffff",
                  titleFontSize: 40,
                },
                Card: {
                  padding: 10,
                },
                FloatButton: {
                  colorBgElevated: "#FFFFFF88",
                },
                Menu: {
                  darkItemSelectedBg: "#687d8c",
                },
              },
            }}
          >
            <App />
          </ConfigProvider>
        </SignalRContext>
      </Provider>
    </I18nConfigureProvicer>
  </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
