import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { MenuProductModel } from "../../../services/menu_products/models/menu_product.model";
import { Col, Modal, Row, Switch } from "antd";
import Title from "antd/es/typography/Title";
import MenuProductServcie from "../../../services/menu_products/menu_product.service";
import CompManagerMenuProductAdd from "./CompManagerMenuProductAdd";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import { toast } from "react-toastify";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";

import "./product-list.css";
import { transactionDate } from "../../../funcs/func";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import {
  ColumnType,
  ThemeDataTable,
  ThemeDataTableSearch,
} from "@theme/ui-components/data-tables";
import { DataTableFilterMeta } from "primereact/datatable";
import ThemeDeleteButton from "@theme/ui-components/buttons/ThemeDeleteButton";
const CompManagerMenuProductList: React.FC = () => {
  const id = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [filters, setFilters] = useState<DataTableFilterMeta>({});
  const tempProductList = useRef<MenuProductModel[]>([]);
  const companyId = useSelector(getCompanyId);
  const menuProductService = useMemo(() => new MenuProductServcie(), []);
  const menuCategoryService = useMemo(() => new MenuCategoryService(), []);
  const fileUploadService = useMemo(() => new FileUploadService(), []);

  const [count, setCount] = useState(0);
  const [data, setData] = useState<MenuProductModel[]>([]);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);

  const getCategory = useCallback(async () => {
    let response = await menuCategoryService.getList(companyId);

    if (response) {
      setCategoryList(response);
    }
  }, [companyId, menuCategoryService]);

  const getProduct = useCallback(async () => {
    let response = await menuProductService.getList(companyId);
    if (response) {
      tempProductList.current = response;
      setData(response);
    }
  }, [companyId, menuProductService]);

  const initLoad = useCallback(async () => {
    setIsLoading(true);
    await getCategory();
    await getProduct();
    setIsLoading(false);
  }, [getCategory, getProduct]);

  useEffect(() => {
    console.info(count);

    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, initLoad]);

  const saveProduct = useCallback(
    async (menuProductModel: MenuProductModel) => {
      setIsLoading(true);
      console.error(menuProductModel);

      if (menuProductModel.id === 0 || menuProductModel.id === undefined) {
        let response = await menuProductService.add(menuProductModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
        }
      } else {
        let response = await menuProductService.update(menuProductModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
        }
      }
      setIsLoading(false);
    },
    [getProduct, menuProductService]
  );
  const deleteMenuProductImage = useCallback(
    async (menuProductModel: MenuProductModel, imagePath: string) => {
      setIsLoading(true);
      let fileResponse = await fileUploadService.deleteFile(imagePath);
      if (fileResponse) {
        let response = await menuProductService.update({
          ...menuProductModel,
          photo: undefined,
        });
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
          setIsLoading(false);
          return response;
        }
      }
      setIsLoading(false);
      return undefined;
    },
    [fileUploadService, getProduct, menuProductService]
  );
  const saveCategory = useCallback(
    async (menuCategoryModel: MenuCategoryModel) => {
      setIsLoading(true);
      let response = await menuCategoryService.add(menuCategoryModel);
      if (response) {
        toast("Kayıt işlemi başarılı.");

        await getCategory();
      } else if (
        menuCategoryModel.photo !== undefined &&
        menuCategoryModel.photo !== ""
      ) {
        fileUploadService.deleteFile(menuCategoryModel.photo);
      }
      setIsLoading(false);
    },
    [fileUploadService, getCategory, menuCategoryService]
  );

  const tablePhoto = (data: MenuProductModel) => {
    const src = data.photo?.toApiImage();

    return <img width={70} height={70} src={src} alt="" />;
  };
  const tableActions = (data: MenuProductModel) => {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <ThemeButton
          loading={isLoading}
          disabled={isLoading}
          severity="success"
          type="button"
          onClick={() => {
            id.current = data.id;
            setModalVisible(true);
            return Promise.resolve();
          }}
          icon={<i className="fa-duotone fa-pen-to-square"></i>}
        />
        <ThemeDeleteButton
          accept={async () => {
            setIsLoading(true);
            let response = await menuProductService.deleteProduct(data.id);
            if (response) {
              if (data.photo !== undefined && data.photo !== "") {
                await fileUploadService.deleteFile(data.photo);
              }
              toast.success("Silme başarılı");
            }
            await getProduct();
            setIsLoading(false);
          }}
          reject={async () => {
            setIsLoading(false);
            return Promise.resolve();
          }}
        />
      </div>
    );
  };
  const tableStatus = (data: MenuProductModel) => {
    return (
      <Switch
        checkedChildren="Aktif"
        disabled
        unCheckedChildren="Pasif"
        defaultChecked={data.status}
      />
    );
  };

  const columnsT: ColumnType[] = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "photo",
      header: "Fotoğraf",
      body: tablePhoto,
    },
    {
      header: "Başlık",
      field: "title",
    },
    {
      header: "Fiyatı",
      field: "price",
    },
    {
      header: "Detay",
      field: "detail",
    },
    {
      header: "Durum",
      field: "status",
      body: tableStatus,
    },
    {
      field: "transactionDate",
      header: "İşlem Zamanı",
      body: transactionDate,
    },
    {
      field: "actions",
      header: "",
      body: tableActions,
    },
  ];
  return (
    <div>
      <Title level={3} className="my-title">
        Ürün Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <ThemeButton
            loading={isLoading}
            onClick={() => {
              id.current = 0;
              setModalVisible(true);
            }}
            label="Yeni Ürün Ekle"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          />
        </Col>
        <Col>
          <ThemeDataTableSearch setFilters={setFilters} filters={filters} />
        </Col>
      </Row>
      <ThemeDataTable
        value={data}
        filters={filters}
        columns={columnsT}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />

      {modalVisible ? (
        <Modal closeIcon={<></>} footer={<></>} open={modalVisible}>
          <CompManagerMenuProductAdd
            isLoading={isLoading}
            deleteMenuProductImage={deleteMenuProductImage}
            categoryList={categoryList}
            closeModal={() => setModalVisible(false)}
            saveProduct={saveProduct}
            saveCategory={saveCategory}
            productService={menuProductService}
            id={id.current}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerMenuProductList;
