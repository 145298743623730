import { InputText, InputTextProps } from "primereact/inputtext";
import React from "react";

interface ThemeInputTextProps
  extends InputTextProps,
    React.RefAttributes<HTMLInputElement> {}
const ThemeInputText: React.FC<ThemeInputTextProps> = ({
  className = "w-full",
  ...rest
}) => {
  return <InputText className="w-full" {...rest} />;
};

export default ThemeInputText;
