import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { TableModel } from "../../../services/tables/models/tabele.model";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import { TableService } from "../../../services/tables/table.service";
import CompManagerTableAdd from "./CompManagerTableAdd";
import { SectionService } from "../../../services/sections/serction.service";
import { SectionModel } from "../../../services/sections/models/section.model";
import { toast } from "react-toastify";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import ThemeDeleteButton from "@theme/ui-components/buttons/ThemeDeleteButton";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import { transactionDate } from "../../../funcs/func";
import {
  ColumnType,
  ThemeDataTable,
  ThemeDataTableSearch,
} from "@theme/ui-components/data-tables";
import { DataTableFilterMeta } from "primereact/datatable";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";
import ThemeQRCode, {
  ThemeQRCodeHandle,
} from "@theme/ui-components/qr-codes/ThemeQRCode";
import ThemeConfirmDialog from "@theme/ui-components/dialogs/ThemeConfirmDialog";
import { confirmDialog } from "primereact/confirmdialog";

const CompManagerTableList = () => {
  const qrCodeRef = useRef<ThemeQRCodeHandle>(null);
  const companyId = useSelector(getCompanyId);
  const tableService = useMemo(() => new TableService(), []);
  const sectionService = useMemo(() => new SectionService(), []);
  const fileUploadService = new FileUploadService();

  const [isLoading, setIsLoading] = useState(false);

  const tableId = useRef("");
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState<DataTableFilterMeta>({});
  const [openModalCompanyAdd, setOpenModalCompanyAdd] =
    useState<boolean>(false);
  const [data, setData] = useState<TableModel[]>([]);
  const [sectionList, setSectionList] = useState<SectionModel[]>([]);
  const tempTableList = useRef<TableModel[]>([]);

  /** const domainUrl = window.location.origin;*/
  const tablePhoto = (data: TableModel) => {
    const src = data.logo ?? "".toApiImage("table.png");

    return <img width={70} height={70} src={src} alt="" />;
  };
  const tableActions = (data: TableModel) => {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <ThemeButton
          loading={isLoading}
          disabled={isLoading}
          severity="success"
          type="button"
          onClick={() => {
            tableId.current = data.id;
            setOpenModalCompanyAdd(true);
          }}
          icon={<i className="fa-duotone fa-pen-to-square"></i>}
        />
        <ThemeDeleteButton
          accept={async () => {
            setIsLoading(true);
            let response = await tableService.deleteById(data.id);
            if (response) {
              if (data.logo !== undefined && data.logo !== "") {
                await fileUploadService.deleteFile(data.logo);
              }
              toast.success("Silme başarılı");
            }
            await getTable();
            setIsLoading(false);
          }}
          reject={async () => {
            setIsLoading(false);
            return Promise.resolve();
          }}
        />
      </div>
    );
  };
  const tableQRCode = (data: TableModel) => {
    return (
      <ThemeButton
        onClick={() => {
          confirmDialog({
            message: (
              <ThemeQRCode
                value={`https://menu.qrmenuscan.com/${data.id}`}
                ref={qrCodeRef}
                icon="/images/Backlink.png"
              />
            ),
            header: "QR Kod",
            acceptLabel: "Kaydet",
            rejectLabel: "Kapat",
            defaultFocus: "accept",
            accept: () => {
              qrCodeRef.current?.downloadQRCode(data.name);
            },
          });
        }}
        icon={<i className="fa-light fa-qrcode"></i>}
      />
    );
  };
  const columnsT: ColumnType[] = [
    {
      field: "logo",
      header: "Fotoğraf",
      body: tablePhoto,
    },
    {
      header: "Ad",
      field: "name",
    },
    {
      header: "Numarası",
      field: "number",
    },
    {
      header: "Kare kod oluştur",
      field: "qrCode",
      body: tableQRCode,
    },
    {
      field: "transactionDate",
      header: "İşlem Zamanı",
      body: transactionDate,
    },
    {
      field: "actions",
      header: "",
      body: tableActions,
    },
  ];
  const getSection = useCallback(async () => {
    let response = await sectionService.getListByCompanyId(companyId);
    if (response) {
      setSectionList(response);
    }
  }, [companyId, sectionService]);

  const getTable = useCallback(async () => {
    let response = await tableService.getListByCompanyId(companyId);
    if (response) {
      setData(response);
      tempTableList.current = response;
    }
  }, [companyId, tableService]);
  const addSection = useCallback(
    async (sectionModel: SectionModel) => {
      setIsLoading(true);
      if (sectionModel) {
        let response = await sectionService.add(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSection();
        }
      }
      setIsLoading(false);
    },
    [getSection, sectionService]
  );
  const addTable = useCallback(
    async (tableModel: TableModel) => {
      setIsLoading(true);
      if (tableModel.id === "") {
        let response = await tableService.add(tableModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getTable();
        }
      } else {
        let response = await tableService.update(tableModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getTable();
        }
      }
      setIsLoading(false);
    },
    [getTable, tableService]
  );

  const initLoad = useCallback(async () => {
    console.info(count);

    setIsLoading(true);
    await getSection();
    await getTable();
    setIsLoading(false);
  }, [getSection, getTable]);

  useEffect(() => {
    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, initLoad]);

  return (
    <div>
      <Title level={3} className="my-title">
        Masa Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <ThemeButton
            loading={isLoading}
            onClick={() => {
              setOpenModalCompanyAdd(true);
            }}
            size="small"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
            label="Yeni Masa Ekle"
          />
        </Col>
        <Col>
          <ThemeDataTableSearch filters={filters} setFilters={setFilters} />
        </Col>
      </Row>
      <ThemeDataTable
        value={data}
        filters={filters}
        columns={columnsT}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
      <ThemeConfirmDialog />
      {openModalCompanyAdd ? (
        <ThemeDialog
          header=""
          visible={openModalCompanyAdd}
          onHide={() => setOpenModalCompanyAdd(false)}
          footer={<></>}
        >
          <CompManagerTableAdd
            id={tableId.current}
            isLoading={isLoading}
            getTableList={getTable}
            tableService={tableService}
            add={addTable}
            addSection={addSection}
            sectionList={sectionList}
          />
        </ThemeDialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerTableList;
