import { Password, PasswordProps } from "primereact/password";
import React from "react";
import "./password.scss";
interface ThemePasswordProps extends PasswordProps {}
const ThemePassword: React.FC<ThemePasswordProps> = ({
  className = "w-full",
  weakLabel = "Zayıf",
  mediumLabel = "Orta",
  strongLabel = "Güçlü",
  promptLabel = "Şifre giriniz",
  toggleMask = true,
  value,
  ...rest
}) => {
  
  return (
    <Password
      value={value}
      className={className}
      toggleMask={toggleMask}
      weakLabel={weakLabel}
      strongLabel={strongLabel}
      mediumLabel={mediumLabel}
      promptLabel={promptLabel}
      {...rest}
    />
  );
};

export default ThemePassword;
