import { ApiUrl } from "../consts/api";

// string-extensions.ts
declare global {
  interface String {
    toApiImage(blankImage?: string): string;
  }
}
export {};
String.prototype.toApiImage = function (blankImage = ""): string {
  if (this.trim().length === 0) {
    return `${ApiUrl}/${blankImage}`;
  }
  return `${ApiUrl}/${this}`;
};
