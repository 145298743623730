import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage' 

import BasketReducer from "./slices/baskets/basker-slice";
import TableReducer from "./slices/tables/tableSlice";
import OrderReducer from "./slices/orders/order_slice";
import AuthReducer from "./slices/auth/auth_slice";
import CompanyReducer from "./slices/companies/company_slice";
import CategoryReducer from "./slices/categories/category_slice";
import ProductReducer from "./slices/products/product_slice";
import PrinterReducer from "./slices/printers/printer_slice";

const rootReducer = combineReducers({
  basket: BasketReducer,
  table: TableReducer,
  order: OrderReducer,
  auth: AuthReducer,
  company: CompanyReducer,
  category: CategoryReducer,
  product: ProductReducer,
  printer: PrinterReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER",
          "persist/FLUSH",
          "persist/PAUSE",
          "persist/PURGE",
        ],
      },
    });
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
