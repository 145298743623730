import React from "react";
import { Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";
import "../../enums/orders/order_status.enum";
import UserProfileComponent from "../../components/UserProfileComponent";
import ThemeMenubar from "@theme/ui-components/menubars/ThemeMenubar";
import { MenuItem } from "primereact/menuitem";
import { getCompany } from "../../react-reduxs/slices/companies/company_slice";
import { useSelector } from "react-redux";

const WaiterLayout: React.FC = () => {
  const company = useSelector(getCompany);
  const { t } = useTranslation();
  const items: MenuItem[] = [
    {
      label: t(I18nPagePropertyEnum.waiterLayoutOrder),
      icon: "fa-duotone fa-plate-utensils",
    },
  ];

  const logo = () => {
    return (
      <div  style={{display:"flex",gap:5,color:'var(--primary-color)',fontWeight:"bold"}}>
        <img
          alt="logo"
          src={
            company?.logo
              ? company.logo.toApiImage()
              : "link-logo.png".toApiImage()
          }
          height="40"
          className="mr-2"
        />
        <p>{company?.title}</p>
      </div>
    );
  };
  const profile = () => {
    return <UserProfileComponent />;
  };
  return (
    <div>
      <ThemeMenubar model={items} start={logo} end={profile} />

    <div style={{paddingLeft:10,paddingRight:10}}>
    <Outlet />
    </div>
    </div>
  );
};

export default WaiterLayout;

/**
 * 
 *    <Layout style={{ overflow: "hidden" }}>
      <Header style={{ padding: "0px 10px 0px 0px" }}>
        <Row>
          <Col flex={1}>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              style={{ width: "calc(100% - 129px)" }}
              items={[
                {
                  key: "1",
                  icon: <i className="fa-duotone fa-plate-utensils"></i>,
                  label: t(I18nPagePropertyEnum.waiterLayoutOrder),
                },
              ]}
            />
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <UserProfileComponent />
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          padding: 4,
          minHeight: "80vh",
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Outlet />
      </Content>
    </Layout>
 */
