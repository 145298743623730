import { Dropdown, Menu, MenuProps, Space } from "antd";

import React from "react";
import "./dropdown.css";
import { ItemType } from "antd/es/menu/interface";
interface UiDropdownListProps {
  items: ItemType[];
  onClick: MenuProps["onClick"];
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}
const UiDropdownList: React.FC<UiDropdownListProps> = ({
  items = [],
  onClick,
  children = <></>,
  header = <></>,
  footer = <></>,
}) => {
  const menu = (
    <div style={{ width: "100%", maxWidth: "350px", paddingRight: 20 }}>
      <div
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: "#FDFDFDE3",
          padding: 5,
          borderBottomColor: "black",
          borderBottomStyle: "groove",
          borderBottomWidth: 1,
        }}
      >
        {header}
      </div>
      <Menu
        items={items}
        onClick={onClick}
        className="custom-scrollbar"
        style={{
          maxHeight: "300px",
          overflowY: "auto",
          borderRadius: 0,
          backgroundColor: "#FDFDFDE3",
          boxShadow: "none",
        }}
      />
      <div
        style={{
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor: "#FDFDFDE3",
          padding: 5,
          borderTopColor: "black",
          borderTopStyle: "groove",
          borderTopWidth: 1,
        }}
      >
        {footer}
      </div>
    </div>
  );

  return (
    <Dropdown
      overlayStyle={{ width: "90%", maxWidth: "350px", paddingRight: 20 }}
      trigger={["click"]}
      dropdownRender={() => menu}
      overlayClassName="mdropdown"
      placement="topLeft"
    >
      <Space style={{ cursor: "pointer", margin: 5, marginBottom: 7 }}>
        {children}
      </Space>
    </Dropdown>
  );
};

export default UiDropdownList;
