import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./firebase-config";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestNotificationPermission = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BO6lk8tipOpsXStpoynESxLkrDjibr9sm6vO7nANrIIn6-2e5nQQYniLbfs5xFOWloRp2zWTe5gBABuZEzZdnlg",
    });
    if (token) {
      return token;
    } else {
      console.error("Failed to get FCM token.");
      return null;
    }
  } catch (error) {
    console.error("Error getting notification permission:", error);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
