import BaseApiService from "../../services/base_api.service";
import { GetChartDto } from "./models/get-chart.dto";
import { RestaurantOccupancyRateDto } from "./models/restaurant-occupancy-rate.dto";

export class ChartService extends BaseApiService {
  constructor() {
    super("Charts");
  }

  async getOrderChartList(
    companyId: number,
    date: string
  ): Promise<GetChartDto[]> {
    const getData = await this.get<GetChartDto[]>(
      `/GetOrderChartList?companyId=${companyId}&date=${date}`
    );
    return getData ?? [];
  }
  getRestaurantOccupancyRateByCompanyId(
    companyId: number
  ): Promise<RestaurantOccupancyRateDto | undefined> {
    return this.get<RestaurantOccupancyRateDto>(
      `/GetRestaurantOccupancyRateByCompanyId?companyId=${companyId}`
    );
  }
}
