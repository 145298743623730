import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../react-reduxs/configure_store";

import {
  getToken,
  setCompanyId,
  setToken,
  setUserId,
} from "../../react-reduxs/slices/auth/auth_slice";
import { Col, List, Row } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";
import i18n from "i18next";
import "../../enums/languages/language.enum";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth/auth.service";
import { LoginModel } from "../../services/auth/models/login.model";
import { UserTypeEnum } from "../../enums/user_type.enum";
import "./login.css";
import {
  getCompanyList,
  setCompanyList,
  setCompanyPublic,
} from "../../react-reduxs/slices/companies/company_slice";
import "react-toastify/dist/ReactToastify.css";
import ThemeCard from "@theme/ui-components/cards/ThemeCard";
import ThemeDynamicForm from "@theme/ui-components/forms/ThemeDynamicForm";
import { RefType } from "@theme/ui-components/forms/form-field-type";

import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import ThemeSelect from "@theme/ui-components/selects/ThemeSelect";
import { DropdownChangeEvent } from "primereact/dropdown";
import { LoginFields } from "./LoginFormField";

const LoginPage = () => {
  const formRef = useRef<RefType>();
  const authService = useMemo(() => new AuthService(), []);
  const [pageGo, setPageGo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(getToken);
  const companyList = useSelector(getCompanyList);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languageChange = useCallback((language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (i18n.options.resources) {
      const resourceKeys = Object.keys(i18n.options.resources);
      setLanguageList(resourceKeys);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (token !== "" && pageGo) {
      const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
      dispatch(
        setUserId(
          parseInt(
            decodedToken[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ] ?? "0"
          )
        )
      );
      if (decodedToken?.userType === UserTypeEnum.Waiter) {
        navigate("/waiter");
      } else if (decodedToken?.userType === UserTypeEnum.Admin) {
        navigate("/admin");
      } else if (decodedToken?.userType === UserTypeEnum.CompanyManager) {
        navigate("/compManager");
      }
    }
    return () => {};
  }, [navigate, pageGo, token]);

  const onFinish = useCallback(
    async (values: LoginModel) => {
      setIsLoading(true);
      try {
        let response = await authService.login(values);
        if (response?.companies) {
          if (response.companies.length > 0) {
            dispatch(setCompanyList(response.companies));
            if (response.companies.length === 1) {
              dispatch(setCompanyId(response.companies[0].id));
              dispatch(setCompanyPublic(response.companies[0]));
              setPageGo(true);
            }
          } else {
            setPageGo(true);
          }
        }
        if (response?.token) {
          let decodeToken = JSON.parse(atob(response.token.split(".")[1]));
          if (decodeToken) {
            if (decodeToken.companyId) {
              dispatch(setCompanyId(decodeToken.companyId));
            }
          }
          dispatch(setToken(response.token));
        }
      } catch (error) {
        // Token ayarlanamadı, uygun bir şekilde işlem yapılabilir (örneğin, kullanıcıya bir hata mesajı gösterilebilir)
      } finally {
        setIsLoading(false);
      }
    },
    [authService, dispatch]
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgb(232 240 254 / 57%)",
      }}
    >
      <ThemeCard className="login-content">
        <Row style={{ height: 50, marginBottom: 10 }}>
          <Col flex={1}>
            <img width={50} src="./images/Backlink.png" alt="" />
          </Col>
          <Col
            style={{
              paddingLeft: 10,
            }}
            flex={2}
          >
            <Title
              level={3}
              style={{
                maxHeight: 3,
                display: "flex",
                alignItems: "center",

                fontWeight: "normal",
                fontFamily: "BoldGroovy",
              }}
            >
              Link {t(I18nPagePropertyEnum.loginTitle)}
            </Title>
          </Col>
        </Row>
        {companyList.length === 0 ? (
          <div>
            <ThemeDynamicForm
              fieldClassName="field col-12"
              ref={formRef}
              onFinish={(values) => onFinish(values)}
              fields={LoginFields}
              initialValues={{ userName: "", password: "" }}
            />
            <ThemeSelect
              disabled
              value={selectedLanguage}
              onChange={(e: DropdownChangeEvent) =>
                languageChange(e.target.value)
              }
              optionLabel="label"
              optionValue="value"
              options={languageList.map((lang) => ({
                label: lang.toText() + " Dil beta sürümdür",
                value: lang,
              }))}
            />
            <br />
            <div className="btn-content">
              <ThemeButton
                loading={isLoading}
                disabled={isLoading}
                icon={
                  <i className="fa-duotone fa-arrow-right-from-arc mr-1"></i>
                }
                onClick={() => {
                  formRef.current?.submitForm();
                }}
                label={`${t(I18nPagePropertyEnum.loginLogin)}`}
              />
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Title level={5}>Firma Seçiniz </Title>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 2,
              }}
              dataSource={companyList}
              style={{
                maxHeight: 400,
                overflowY: "auto",
                overflowX: "hidden",
              }}
              renderItem={(item) => (
                <List.Item>
                  <ThemeCard
                    style={{ marginTop: 10, marginLeft: 10 }}
                    title={item.title + " " + (item.subheading ?? "")}
                  >
                    <ThemeButton
                      key={"select-company"}
                      onClick={() => {
                        dispatch(setCompanyId(item.id));
                        dispatch(setCompanyPublic(item));
                        setPageGo(true);
                      }}
                      label="Firmayı Seç"
                    />
                    ,
                    <img
                      width={100}
                      src={
                        item?.logo.length > 0
                          ? item.logo.toApiImage()
                          : "link-logo.png".toApiImage()
                      }
                      alt={item.logo}
                    />
                    <Title level={5}>{item.subheading}</Title>
                  </ThemeCard>
                </List.Item>
              )}
            />
          </div>
        )}
      </ThemeCard>
    </div>
  );
};

export default LoginPage;
