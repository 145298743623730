import { Dropdown, DropdownProps } from "primereact/dropdown";
import React from "react";

interface ThemeSelectProps extends DropdownProps {}
const ThemeSelect:React.FC<ThemeSelectProps> = ({
  filter = true,
  className = "w-full",
  ...rest
}) => {
  return <Dropdown {...rest} filter={filter} className={className} />;
};

export default ThemeSelect;
