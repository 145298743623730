import { ConfirmPopup, ConfirmPopupProps } from "primereact/confirmpopup";
import React from "react";
import './confirm.popup.scss'
interface ThemeConfirmPopupProps extends ConfirmPopupProps {}

const ThemeConfirmPopup: React.FC<ThemeConfirmPopupProps> = ({ ...rest }) => {
  return <ConfirmPopup {...rest} />;
};

export default ThemeConfirmPopup;
