import React, { useCallback, useEffect, useRef, useState } from "react";
import { SectionService } from "../../../services/sections/serction.service";
import { SectionModel } from "../../../services/sections/models/section.model";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { Col, Row } from "antd";
import { toast } from "react-toastify";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import Title from "antd/es/typography/Title";
import CompManagerSectionAdd from "./CompManagerSectionAdd";
import ThemeDeleteButton from "@theme/ui-components/buttons/ThemeDeleteButton";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import {
  ColumnType,
  ThemeDataTable,
  ThemeDataTableSearch,
} from "@theme/ui-components/data-tables";
import { DataTableFilterMeta } from "primereact/datatable";
import { transactionDate } from "../../../funcs/func";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";

const CompanyManagerSectionList = () => {
  const companyId = useSelector(getCompanyId);

  const sectionService = new SectionService();
  const fileUploadService = new FileUploadService();

  const section = useRef<SectionModel>();
  const [filters, setFilters] = useState<DataTableFilterMeta>({});
  const [modalVisible, setModalVisible] = useState(false);
  const [sectionList, setSectionList] = useState<SectionModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const tempSectionList = useRef<SectionModel[]>([]);
  const getSectionList = useCallback(async () => {
    let response = await sectionService.getListByCompanyId(companyId);
    if (response) {
      setSectionList(response);
      tempSectionList.current = response;
    }
  }, [companyId, sectionService]);

  useEffect(() => {
    console.info(count);

    setCount((co) => {
      if (co === 0) {
        getSectionList();
      }
      return 1;
    });
    return () => {};
  }, [getSectionList,count]);

  const addSection = useCallback(
    async (sectionModel: SectionModel) => {
      setIsLoading(true);
      if (sectionModel.id === 0 || sectionModel.id === undefined) {
        let response = await sectionService.add(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSectionList();
        }
      } else {
        let response = await sectionService.update(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSectionList();
        }
      }
      setModalVisible(false);
      setIsLoading(false);
    },
    [getSectionList, sectionService]
  );
  const deleteImage = useCallback(
    async (path: string, sectionModel: SectionModel) => {
      setIsLoading(true);
      sectionModel.logo = undefined;
      let response = await sectionService.update(sectionModel);
      if (response) {
        let response2 = await fileUploadService.deleteFile(path);
        if (response2) {
          toast.success("Silme işlemi başarılı");
        } else {
          sectionModel.logo = path;
          let response3 = await sectionService.update(sectionModel);

          return response3;
        }
        setIsLoading(false);
        return response;
      }
      setIsLoading(false);
      return sectionModel;
    },
    []
  );

  const tablePhoto = (data: SectionModel) => {
    return (
      <img
        width={70}
        height={70}
        src={data.logo ?? "".toApiImage("restaurant.png")}
        alt=""
      />
    );
  };
  const tableActions = (data: SectionModel) => {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <ThemeButton
          loading={isLoading}
          disabled={isLoading}
          severity="success"
          type="button"
          onClick={() => {
            section.current = data;
            setModalVisible(true);
          }}
          icon={<i className="fa-duotone fa-pen-to-square"></i>}
        />
        <ThemeDeleteButton
          accept={async () => {
            setIsLoading(true);
            const response = await sectionService.deleteById(data.id);
            if (response) {
              if (data.logo !== undefined && data.logo !== "") {
                await fileUploadService.deleteFile(data.logo);
              }
              toast.success("Silme başarılı");
            }
            await getSectionList();
            setIsLoading(false);
          }}
          reject={async () => {
            setIsLoading(false);
            return Promise.resolve();
          }}
        />
      </div>
    );
  };
  const columnsT: ColumnType[] = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "logo",
      header: "Fotoğraf",
      body: tablePhoto,
    },
    {
      field: "name",
      header: "Adı",
    },
    {
      field: "transactionDate",
      header: "İşlem Zamanı",
      body: transactionDate,
    },
    {
      field: "actions",
      header: "",
      body: tableActions,
    },
  ];
  return (
    <div>
      <Title level={3} className="my-title">
        Ürün Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <ThemeButton
            loading={isLoading}
            onClick={() => {
              section.current = undefined;
              setModalVisible(true);
            }}
            icon={<i className="fa-solid fa-rectangle-history-circle-plus" />}
          >
            Yeni Bölüm Ekle
          </ThemeButton>
        </Col>
        <Col>
          <ThemeDataTableSearch setFilters={setFilters} filters={filters} />
        </Col>
      </Row>
      <ThemeDataTable
        className="mt-2"
        value={sectionList}
        columns={columnsT}
        filters={filters}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
      {modalVisible ? (
        <ThemeDialog
          header={""}
          visible={modalVisible}
          onHide={() => setModalVisible(false)}
        >
          <CompManagerSectionAdd
            isLoading={isLoading}
            addSection={addSection}
            sectionModel={section.current}
            deleteMenuSectionImage={deleteImage}
            closeModal={() => setModalVisible(false)}
          />
        </ThemeDialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyManagerSectionList;
