import {
  FieldActionType,
  FormFieldType,
} from "@theme/ui-components/forms/form-field-type";
import ThemeInputText from "@theme/ui-components/inputs/ThemeInputText";
import ThemePassword from "@theme/ui-components/inputs/ThemePassword";
import * as Yup from "yup";

const UserNameField = ({
  values,
  fieldName,
  handleChange,
  handleBlur,
}: FieldActionType) => {
  return (
    <ThemeInputText
      name="userName"
      value={values[fieldName]}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

const PasswordField = ({
  values,
  handleChange,
  handleBlur,
  fieldName,
}: FieldActionType) => (
  <ThemePassword 
    name="password"
    value={values[fieldName]}
    onChange={handleChange}
    onBlur={handleBlur}
  />
);
const LoginFields: FormFieldType[] = [
  {
    name: "userName",
    label: "Kullanıcı Adı",
    validation: Yup.string().required("Kullanıcı adı zorunludur"),
    chield: (values, handleChange, handleBlur, fieldName) =>
      UserNameField({ values, fieldName, handleChange, handleBlur }),
  },
  {
    name: "password",
    label: "Şifre",
    validation: Yup.string().required("Şifre zorunludur"),
    chield: (values, handleChange, handleBlur, fieldName) =>
      PasswordField({ values, fieldName, handleChange, handleBlur }),
  },
];

export { LoginFields };
