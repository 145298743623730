import React, { useCallback, useRef, useState } from "react";
import "../template-4.css";
import { FaShoppingCart } from "react-icons/fa";
import { MenuProductAmountType } from "../../../../../models/menu_product_amount_type";
import { MenuProductModel } from "../../../../../services/menu_products/models/menu_product.model";
import { BasketModel } from "../../../../../models/baskets/basket.model";
import { addBasket } from "../../../../../react-reduxs/slices/baskets/basker-slice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../../../../react-reduxs/slices/companies/company_slice";
import { AppDispatch } from "../../../../../react-reduxs/configure_store";
import { message } from "antd";
import { I18nPagePropertyEnum } from "../../../../../i18n/i18n_page_property";
import "./t4-basket-add.scss";
import ThemeConfirmPopup from "@theme/ui-components/dialogs/ThemeConfirmPopup";
import { InputTextarea } from "primereact/inputtextarea";
import ThemeConfirmPopupContent from "@theme/ui-components/dialogs/ThemeConfirmPopupContent";

interface T4BasketAddBtnComponentProps {
  menuProductAmountList: MenuProductAmountType[];
  menuProduct: MenuProductModel;
  tableId: string;
  removeMenuProductAmount: any;
}
const T4BasketAddBtnComponent: React.FC<T4BasketAddBtnComponentProps> = ({
  tableId,
  menuProduct,
  menuProductAmountList,
  removeMenuProductAmount,
}) => {
  const buttonEl = useRef(null);
  const [visible, setVisible] = useState(false);
  const company = useSelector(getCompany);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [orderNote, setOrderNote] = useState("");

  const success = useCallback(
    (value: number) => {
      messageApi.open({
        type: "success",
        content: `${value} Ürün Sepete Eklendi. 😎`,
      });
    },
    [messageApi]
  );

  const basketAdd = useCallback(
    (menuProduct: MenuProductModel) => {
      let amount =
        menuProductAmountList.find((x) => x.menuProductId === menuProduct.id)
          ?.amount ?? 1;

      dispatch(
        addBasket({
          id: 0,
          tableId: tableId,
          menuProductId: menuProduct.id,
          menuProductName: menuProduct.title,
          menuProductPhoto: menuProduct.photo,
          price: menuProduct.price,
          amount: amount,
          orderNote: orderNote,
        } as BasketModel)
      );
      success(amount);
      removeMenuProductAmount(menuProduct.id);
    },
    [
      menuProductAmountList,
      dispatch,
      tableId,
      orderNote,
      success,
      removeMenuProductAmount,
    ]
  );

  const onBasketPopup = useCallback(() => {
    setVisible(true);
  }, []);
  const content = (acceptBtnRef: any, rejectBtnRef: any, hide: () => void) => {
    return (
      <ThemeConfirmPopupContent
        acceptBtnLabel={t(I18nPagePropertyEnum.commonOk)}
        acceptBtnRef={acceptBtnRef}
        accept={() => {
          basketAdd(menuProduct);
          setVisible(false);
          setOrderNote("");
          hide();
        }}
        rejectBtnLabel={t(I18nPagePropertyEnum.commonCancel)}
        rejectBtnRef={rejectBtnRef}
        reject={() => {
          setOrderNote("");
          setVisible(false);
          hide();
        }}
        header={
          <div className="basket-add-header">
            <i
              style={{ color: "green", marginRight: 5 }}
              className="fa-duotone fa-cart-plus"
            />
            <p>{t(I18nPagePropertyEnum.menuProductAddToBasket)}</p>
          </div>
        }
      >
        <label>{t(I18nPagePropertyEnum.menuProductOrderNote)}</label>
        <InputTextarea
          value={orderNote}
          onChange={(e) => {
            setOrderNote(e.target.value);
          }}
          rows={2}
        />
      </ThemeConfirmPopupContent>
    );
  };
  return (
    <div>
      {contextHolder}
      {company?.orderAtTheTable ? (
        <button ref={buttonEl} className="btn" onClick={(e) => onBasketPopup()}>
          <FaShoppingCart /> Sepet Ekle
        </button>
      ) : (
        <></>
      )}
      <ThemeConfirmPopup
        target={buttonEl.current!} onHide={()=>{
          setVisible(false)
        }}
        visible={visible}
        content={({ acceptBtnRef, rejectBtnRef, hide }) =>
          content(acceptBtnRef, rejectBtnRef, hide)
        }
      />
    </div>
  );
};

export default T4BasketAddBtnComponent;
