import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CompanyService } from "../services/companies/company.service";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyId } from "../react-reduxs/slices/auth/auth_slice";
import {
  getCompany,
  setCompanyPublic,
} from "../react-reduxs/slices/companies/company_slice";
import { setTable, setTableId } from "../react-reduxs/slices/tables/tableSlice";
import { useNavigate, useParams } from "react-router-dom";
import QrTemplate1Page from "./companies/templates/QrTemplate1Page";
import Template2 from "./companies/templates/Template2";
import Template3 from "./companies/templates/template_3/Tempalte3";
import Title from "antd/es/typography/Title";
import Template4 from "./companies/templates/template-4/Template4";
import AppInstall from "./tests/AppInstall";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import { TableService } from "../services/tables/table.service";

const TemplateRedirect: React.FC = () => {
  const navigation = useNavigate();
  const { tableId } = useParams();
  const dispatch = useDispatch();
  const companyService = useMemo(() => new CompanyService(), []);
  const tableService = useMemo(() => new TableService(), []);

  const company = useSelector(getCompany);
  const [count, setCount] = useState<number>(0);

  const getCompanyL = useCallback(async () => {
    if (tableId !== "" && tableId) {
      let tableResponse = await tableService.getById(tableId);
      let response = await companyService.getCompanyByTableId(tableId);
      if (response && tableResponse) {
        dispatch(setTable(tableResponse));
        dispatch(setCompanyId(response.id));
        dispatch(setCompanyPublic(response as any));
        dispatch(setTableId(tableId ?? ""));
      } else {
        dispatch(setCompanyId(0));
        dispatch(setTable(undefined));
        dispatch(setCompanyPublic(undefined));
        dispatch(setTableId(tableId));
      }
    } else {
      dispatch(setCompanyId(0));
      dispatch(setCompanyPublic(undefined));
      dispatch(setTable(undefined));
      dispatch(setTableId(""));
    }
  }, [companyService, tableService, dispatch, tableId]);

  useEffect(() => {
    console.error(count);
    setCount((cn) => {
      if (cn === 0) {
        getCompanyL();
      }
      return 1;
    });
    return () => {};
  }, [getCompanyL]);

  if (company?.templateId === 1) {
    return <QrTemplate1Page />;
  } else if (company?.templateId === 2) {
    return <Template2 />;
  } else if (company?.templateId === 3) {
    return <Template3 />;
  } else if (company?.templateId === 4) {
    return <Template4 />;
  } else if (tableId === undefined) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: 10,
        }}
      >
        <img width={200} height={200} alt="" src="/gifs/settings.gif" />
        <p className="text-2xl text-left font-bold ">Yapım Aşamasında! </p>
        <ThemeButton
          onClick={() => {
            navigation("/login");
          }}
          label="Giriş Yap"
        />
        <br />
        <AppInstall />
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 30,
        }}
      >
        <img width={200} height={200} alt="" src="/gifs/loading.gif" />
        <Title>Yönlendiriliyorsunuz ... </Title>
      </div>
    );
  }
};

export default TemplateRedirect;
