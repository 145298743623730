import { Button, Col, Divider, Flex, Row } from "antd";

import React, { useCallback, useState } from "react";
import { TableModel } from "../../../../services/tables/models/tabele.model";
import { OrderStateModel } from "../../../../services/orders/models/order_state.model";
import { OrderProductStatusEnum } from "../../../../enums/orders/order_status.enum";
import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../../../i18n/i18n_page_property";
import TableOrderListComponent from "../table_orders/TableOrderListComponent";
import { WaiterModel } from "../../../../models/waiter.model";
import { OrderProductStatusModel } from "../../../../services/order_product_statuses/models/order_product_status.model";
import { ApiUrl, WaiterHubUrl } from "../../../../consts/api";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../../react-reduxs/slices/auth/auth_slice";
import * as signalR from "@microsoft/signalr";
import { WaiterHubEnum } from "../../../../enums/waiter_hub.enum";
import CloseOrderDrawer from "./CloseOrderDrawer";
import WaiterTableProductAddComp from "../products/WaiterTableProductAddComp";
import ThemeCard from "@theme/ui-components/cards/ThemeCard";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import { Badge } from "primereact/badge";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";

interface WaiterTableDetailOrderModalProps {
  item: TableModel;
  order?: OrderStateModel;
  waiterModel?: WaiterModel;
  orderProductStatusList: OrderProductStatusModel[];
  updateOrderProductStatus: any;
}
const WaiterTableDetailOrderModal: React.FC<
  WaiterTableDetailOrderModalProps
> = ({
  item,
  order,
  waiterModel,
  orderProductStatusList,
  updateOrderProductStatus,
}) => {
  const companyId = useSelector(getCompanyId);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback((value: boolean) => {
    setIsModalOpen(value);
  }, []);

  const getTableOrderCount = useCallback(() => {
    if (order?.orderProductList) {
      let tableOrderProductList =
        order?.orderProductList.filter(
          (x) => x.status.text === OrderProductStatusEnum.pendingApproval
        ) ?? [];
      return tableOrderProductList.length;
    }
  }, [order?.orderProductList]);
  return (
    <>
      <ThemeCard
        title={item.name}
        severity={!(order || waiterModel) ? "danger" : "success"}
      >
        <Badge
          value={getTableOrderCount()}
          size={"large"}
          severity={!(order || waiterModel) ? "danger" : "success"}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop:10,
            gap: 5,
          }}
        >
          <img
            style={{ width: "100%", height: 130, objectFit: "fill" }}
            alt="logo"
            src={
              item.logo === "" || item.logo === undefined
                ? "/images/table.png"
                : ApiUrl + "/" + item.logo
            }
          />
          <WaiterTableProductAddComp tableId={item.id} />
          <ThemeButton
            severity={!(order || waiterModel) ? "danger" : "success"}
            disabled={!(order || waiterModel)}
            onClick={() => {
              if (order || waiterModel) {
                toggleModal(true);
              }
            }}
          >
            Geçmiş Siparişler
          </ThemeButton>
        </div>
        {waiterModel ? (
          <div
            style={{
              position: "absolute",
              bottom: "155px",
              left: "2px",

              fontSize: 10,
              display: "flex",
              alignItems: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
            <i
              style={{ color: "red", fontSize: 15, marginRight: 5 }}
              className="fa-duotone fa-bell-ring"
            ></i>
            {t(I18nPagePropertyEnum.waiterTheWaiterIsCalling)}
          </div>
        ) : (
          <></>
        )}
      </ThemeCard>

      {isModalOpen ? (
        <ThemeDialog
          visible={isModalOpen}
          maximizable={true}
          onHide={() => {
            toggleModal(false);
          }}
          footer={
            <Row>
              <Col flex={1} style={{ padding: 5 }}>
                <Flex
                  wrap="wrap"
                  gap="small"
                  className="site-button-ghost-wrapper"
                >
                  <CloseOrderDrawer order={order?.order} />

                  <Button
                    disabled={waiterModel === undefined}
                    onClick={async () => {
                      const connection = new signalR.HubConnectionBuilder()
                        .withUrl(WaiterHubUrl(companyId).url)
                        .withAutomaticReconnect()
                        .configureLogging(signalR.LogLevel.Information)
                        .build();

                      try {
                        await connection
                          .start()
                          .then(() => {
                            console.log("SignalR bağlantısı kuruldu.WaiterHub");
                          })
                          .catch((error) => {
                            console.error(
                              "SignalR bağlantısı başlatılırken hata oluştu:",
                              error
                            );
                          });
                        console.error(companyId, item.id);

                        if (companyId > 0) {
                          await connection.invoke(
                            "WaiterEndCall",
                            parseInt(companyId.toString()),
                            item.id
                          );
                        }
                      } catch (error) {
                        console.error(error);
                      } finally {
                        connection.stop();
                      }
                    }}
                    type="dashed"
                  >
                    Garson Çağrı Onay
                  </Button>
                </Flex>
              </Col>

              <Col style={{ padding: 5 }}>
                <Flex
                  wrap="wrap"
                  gap="small"
                  className="site-button-ghost-wrapper"
                >
                  <Button
                    onClick={() => {
                      toggleModal(false);
                    }}
                    type="primary"
                  >
                    {t(I18nPagePropertyEnum.commonOk)}
                  </Button>
                </Flex>
              </Col>
            </Row>
          }
        >
          <TableOrderListComponent
            tableId={order?.order.tableId}
            orderProductStatusList={orderProductStatusList}
            updateOrderProductStatus={updateOrderProductStatus}
            orderState={order}
          />
          <Divider />
        </ThemeDialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default WaiterTableDetailOrderModal;
