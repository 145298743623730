import { Button } from "antd";
import React, { useState } from "react";
import WaiterProductList from "./WaiterProductList";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";

type WaiterTableProductAddCompProps = {
  tableId: string;
};
const WaiterTableProductAddComp: React.FC<WaiterTableProductAddCompProps> = ({
  tableId,
}) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" variant="filled" onClick={showDrawer}>
        Sipariş Gir
      </Button>
      {open ? (
        <ThemeDialog
          maximizable
          maximized
          header="Sipariş Oluştur"
          visible={open}
          onHide={onClose}
        >
          <WaiterProductList tableId={tableId} />
        </ThemeDialog>
      ) : null}
    </div>
  );
};

export default WaiterTableProductAddComp;
