import React, { useEffect, useRef, useState } from "react";
import { I18nPagePropertyEnum } from "../i18n/i18n_page_property";
import {
  getToken,
  loginOut,
  setCompanyId,
} from "../react-reduxs/slices/auth/auth_slice";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../react-reduxs/configure_store";
import {
  setCompanyList,
  setCompanyPublic,
} from "../react-reduxs/slices/companies/company_slice";
import PasswordReset from "./PasswordReset";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";

const UserProfileComponent = () => {
  const token = useSelector(getToken);
  const [userName, setUserName] = useState<string>();
  const [modalVisible, setModalVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const menuRight = useRef<Menu>(null);
  const items: MenuItem[] = [
    {
      label: "Profile",
      items: [
        {
          label: t(I18nPagePropertyEnum.commonLogOut),
          icon: "pi pi-sign-out",
          command(event) {
            dispatch(loginOut());
            dispatch(setCompanyPublic(undefined));
            dispatch(setCompanyList([]));
            dispatch(setCompanyId(0));
          },
        },
        {
          label: "Şifre Yenile",
          icon: "pi pi-refresh",
          command(event) {
            setModalVisible(true);
          },
        },
      ],
    },
  ];
  useEffect(() => {
    if (token) {
      let decodeToken = JSON.parse(atob(token.split(".")[1]));
      if (decodeToken) {
        setUserName(
          decodeToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ]
        );
      }
    }
    return () => {};
  }, [token]);
  return (
    <div>
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
        onShow={() => setMenuVisible(true)}
        onHide={() => setMenuVisible(false)}
      />
      <ThemeButton
        label={userName ?? " name found"}
        icon={!menuVisible ? "pi pi-angle-right" : "pi pi-angle-down"}
        className="mr-2"
        onClick={(event) => {
          menuRight?.current?.toggle(event);
        }}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
      {modalVisible ? (
        <ThemeDialog
          visible={modalVisible}
          onHide={() => {
            setModalVisible(false);
          }}
        >
          <PasswordReset />
        </ThemeDialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserProfileComponent;
