import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
interface ThemeLoadingProps {
  loading?: boolean;
  children?: React.ReactNode;
}
const ThemeLoading: React.FC<ThemeLoadingProps> = ({loading,children}) => {
    if (loading) {
        return <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
    }
  return children ? <>{children}</> : null;
};

export default ThemeLoading;
