import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { ChartService } from "../../../services/charts/chart.service";
import ThemeCard from "@theme/ui-components/cards/ThemeCard";
import { Chart } from "primereact/chart";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import ThemeLoading from "@theme/ui-components/loadings/ThemeLoading";

const RestaurantOccupancyRate = () => {
  const chartService = useMemo(() => new ChartService(), []);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(getCompanyId);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const getRestaurantOccupancyRate = useCallback(() => {
    if (companyId > 0) {
      setLoading(true);
      chartService
        .getRestaurantOccupancyRateByCompanyId(companyId)
        .then((response) => {
          if (response) {
            const data = {
              labels: ["Dolu", "Boş"],
              datasets: [
                {
                  data: [
                    response.numberOfOccupiedTables,
                    response.totalNumberOfTables -
                      response.numberOfOccupiedTables,
                  ],
                  backgroundColor: ["#FF6384", "#36A2EB"],
                  hoverBackgroundColor: ["#FF6384", "#36A2EB"],
                },
              ],
            };
            setChartData(data);
          }
        })
        .finally(() => setLoading(false));
      const options = {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      };
      setChartOptions(options);
    }
  }, [companyId, chartService]);
  useEffect(() => {
    getRestaurantOccupancyRate();
    const interval = setInterval(() => {
      getRestaurantOccupancyRate();
    }, 60000);
    return () => clearInterval(interval);
  }, [getRestaurantOccupancyRate]);
  const header = () => {
    return (
      <div className="grid w-full p-1">
        <div className="col text-center font-bold">
          <p>Restoran Doluluk Oranı</p>
        </div>
        <div className="col-1">
          <ThemeButton
            onClick={() => getRestaurantOccupancyRate()}
            icon="pi pi-refresh"
          />
        </div>
      </div>
    );
  };
  return (
    <ThemeCard className="w-full" header={header()}>
      <ThemeLoading loading={loading}>
        <Chart
          type="pie"
          data={chartData}
          options={chartOptions}
          className="w-full md:w-30rem"
        />
      </ThemeLoading>
    </ThemeCard>
  );
};

export default RestaurantOccupancyRate;
