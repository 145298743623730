import { Dialog, DialogProps } from "primereact/dialog";
import React from "react";
import "./dialog.scss";

interface ThemeDialogType extends DialogProps {}
/**
 * Prime ng Dialog genişletilmiş hali çoğu özellik çalışmaktadır.
 */
const ThemeDialog: React.FC<ThemeDialogType> = ({
  header = "Header", // Varsayılan başlık
  visible = false,

  children,
  ...rest
}) => {
  return (
    <Dialog
      header={header}
      visible={visible}
      {...rest} // Ek özellikler
    >
      {children}
    </Dialog>
  );
};

export default ThemeDialog;
