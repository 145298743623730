import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import { Col, Form, Input, InputNumber, Row, Select, Space, Steps } from "antd";
import { Templates } from "../../../consts/templates.cons";
import { CurrencyService } from "../../../services/currencies/currency.service";
import { CurrencyModel } from "../../../services/currencies/models/currency.model";
import { CompanyService } from "../../../services/companies/company.service";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import Title from "antd/es/typography/Title";
import { ApiUrl } from "../../../consts/api";
import FileUploadComponent from "../../tests/FileUploadComponent";
import {
  getCompanyList,
  setCompanyList,
  setCompanyPublic,
} from "../../../react-reduxs/slices/companies/company_slice";
import FileUploadComponentFull from "../../tests/FileUploadComponentFull";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";

const CompManagerEdit = () => {
  const fileUploadService = new FileUploadService();
  const currencyService = useMemo(() => new CurrencyService(), []);
  const companyService = useMemo(() => new CompanyService(), []);
  const dispatch = useDispatch();
  const [deleteCompanyLogo, setDeleteCompanyLogo] = useState("");
  const [deleteCompanyBackground, setDeleteCompanyBackground] = useState("");
  const companyList = useSelector(getCompanyList);
  const [isLoading, setIsLoading] = useState(false);
  const [templateList] = useState<any[]>(Templates);
  const [count, setCount] = useState(0);
  const [currencyList, setCurrencyList] = useState<CurrencyModel[]>([]);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const companyId = useSelector(getCompanyId);
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyBackground, setCompanyBackground] = useState("");

  const onFinish = async (values: any) => {
    setIsLoading(true);
    let response = await companyService.upadateByCompanyManager({
      ...values,
      logo: companyLogo,
      backgroundImage: companyBackground,
    });
    if (response) {
      toast.success("Kayıt işlemi başarılı");
    }
    if (response !== undefined) {
      if (response.id !== 0) {
        if (deleteCompanyLogo !== "" && deleteCompanyLogo !== undefined) {
          fileUploadService.deleteFile(deleteCompanyLogo);
        }
        form.setFieldValue("id", response.id);
        dispatch(setCompanyPublic(response));
        let compa = companyList.findIndex((x) => x.id === (response?.id ?? 0));
        console.error(response, compa, companyList);
        if (compa !== -1 && response !== undefined) {
          // Create a new array instead of directly modifying the existing array
          let newList = [...companyList]; // Spread the existing companyList into a new array
          newList[compa] = response; // Update the element at the found index
          console.error(newList);
          dispatch(setCompanyList(newList)); // Dispatch the updated array
        }
        next();
      }
      setIsLoading(false);
      window.location.reload();
    }
    setIsLoading(false);
  };

  const getCurrencyList = useCallback(async () => {
    let response = await currencyService.getList();
    console.info(deleteCompanyBackground, count);
    if (response) {
      setCurrencyList(response);
    }
  }, [currencyService]);

  const getCompanyById = useCallback(async () => {
    let response = await companyService.getByCompanyId(companyId ?? 0);

    if (response) {
      console.error(response);

      form.setFieldValue("id", response.id);
      form.setFieldValue("title", response.title);
      form.setFieldValue("connection", response.connection);
      form.setFieldValue("subheading", response.subheading);
      form.setFieldValue("currencyId", response.currencyId);
      form.setFieldValue("templateId", response.templateId);
      setCompanyBackground(response.backgroundImage);
      setCompanyLogo(response.logo);
      setCurrent(current + 1);
    }
  }, [companyService, current, form, companyId]);

  const initload = useCallback(async () => {
    setIsLoading(true);
    if (companyId !== 0) {
      await getCompanyById();
    }
    await getCurrencyList();
    setIsLoading(false);
  }, [getCompanyById, getCurrencyList, companyId]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        initload();
      }
      return 1;
    });
    return () => {};
  }, [initload]);
  const delteCompanyLogoOnclick = useCallback(() => {
    setDeleteCompanyLogo(companyLogo);
    setCompanyLogo("");
  }, [companyLogo]);
  const deleteCompanyBackgroundOnclick = useCallback(() => {
    setDeleteCompanyBackground(companyBackground);
    setCompanyBackground("");
  }, [companyBackground]);
  const onFinishFailed = () => {};
  const next = () => {
    setCurrent(1);
  };

  return (
    <div style={{ maxHeight: "70vh", overflow: "auto" }}>
      <Steps
        current={current}
        direction="vertical"
        items={[
          {
            title: "Firma Düzenle",
            description: (
              <div>
                <div>
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Row style={{ gap: 3 }}>
                      <Form.Item
                        name="id"
                        label=""
                        rules={[{ required: true }]}
                        initialValue={companyId}
                      >
                        <InputNumber
                          style={{ display: "none" }}
                          placeholder=""
                        />
                      </Form.Item>
                      <Col style={{ marginRight: 20 }} flex={1}>
                        <Title style={{ margin: 0 }} level={5}>
                          Firma Logo
                        </Title>
                        {companyLogo !== undefined && companyLogo !== "" ? (
                          <>
                            <img
                              width={100}
                              src={ApiUrl + "/" + companyLogo}
                              alt=""
                            />
                            <ThemeButton
                              onClick={delteCompanyLogoOnclick}
                              severity="danger"
                              loading={isLoading}
                              disabled={isLoading}
                              icon="pi pi-trash"
                            />
                          </>
                        ) : (
                          <FileUploadComponent
                            path={"Companies/Images"}
                            setImageUrlParam={setCompanyLogo}
                          />
                        )}
                      </Col>
                      <Col flex={1}>
                        <Title
                          style={{
                            margin: 0,
                            fontWeight: "normal",
                            marginBottom: 8,
                          }}
                          level={5}
                        >
                          Arka plan fotoğrafı
                        </Title>
                        {companyBackground !== undefined &&
                        companyBackground !== "" ? (
                          <>
                            <img
                              width={100}
                              src={ApiUrl + "/" + companyBackground}
                              alt=""
                            />
                            <ThemeButton
                              onClick={deleteCompanyBackgroundOnclick}
                              severity="danger"
                              icon={<i className="fa-duotone fa-trash"></i>}
                            />
                          </>
                        ) : (
                          <FileUploadComponentFull
                            path={"Companies/Images"}
                            setImageUrlParam={setCompanyBackground}
                          />
                        )}
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="title"
                          label="Başlık"
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Başlık" />
                        </Form.Item>
                      </Col>

                      <Col flex={1}>
                        <Form.Item name="subheading" label="Açıklama">
                          <Input placeholder="Açıklama" />
                        </Form.Item>
                      </Col>

                      <Col flex={1}>
                        <Form.Item
                          name="currencyId"
                          label="Para birimi "
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder=" "
                            style={{ width: "100%", minWidth: 150 }}
                            options={currencyList.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          name="templateId"
                          label="Şablonlar"
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder=" "
                            style={{ width: "100%", minWidth: 150 }}
                            options={templateList.map((item) => ({
                              value: item.id,
                              label: item.text,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Space>
                        <ThemeButton
                          type="submit"
                          loading={isLoading}
                          disabled={isLoading}
                          label="Kaydet"
                          icon="pi pi-save"
                        />
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CompManagerEdit;
