import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";

interface ThemeQRCodeProps {
  value: string;
  icon?: string;
  logoSize?: number;
  qrSize?: number;
}

export interface ThemeQRCodeHandle {
  downloadQRCode: (fileName?: string) => void;
}

const ThemeQRCode = forwardRef<ThemeQRCodeHandle, ThemeQRCodeProps>(
  ({ value, icon, logoSize = 50, qrSize = 300 }, ref) => {
    const qrRef = useRef<HTMLDivElement>(null); // QR kodunu referanslamak için

    useImperativeHandle(ref, () => ({
      downloadQRCode: (fileName: string = "qrcode") => {
        fileName = fileName + ".png";
        if (qrRef.current) {
          const canvas = qrRef.current.querySelector("canvas");
          if (canvas) {
            const qrCanvas = document.createElement("canvas");
            const context = qrCanvas.getContext("2d");

            if (context) {
              // QR kodunun boyutunu ayarla
              qrCanvas.width = qrSize;
              qrCanvas.height = qrSize;

              // QR kodunu yeni canvas'a çiz
              context.drawImage(canvas, 0, 0, qrSize, qrSize);

              // Logoyu ekle
              if (icon) {
                const logo = new Image();
                logo.src = icon;
                logo.onload = () => {
                  const logoX = (qrSize - logoSize) / 2;
                  const logoY = (qrSize - logoSize) / 2;
                  context.drawImage(logo, logoX, logoY, logoSize, logoSize);

                  // Yeni canvas'ı indir
                  const dataURL = qrCanvas.toDataURL("image/png");
                  const link = document.createElement("a");
                  link.href = dataURL;
                  link.download = fileName;
                  link.click();
                };
              } else {
                // Eğer logo yoksa QR kodunu indir
                const dataURL = qrCanvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = dataURL;
                link.download = fileName;
                link.click();
              }
            }
          }
        }
      },
    }));

    return (
      <div
        ref={qrRef}
        style={{ position: "relative", width: qrSize, height: qrSize }}
      >
        <QRCodeCanvas
          value={value}
          size={qrSize}
          bgColor="#ffffff"
          fgColor="#000000"
          level="H" // Yüksek hata toleransı için
          style={{ display: "block" }}
        />
        {icon && (
          <img
            src={icon}
            alt="Logo"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: logoSize,
              height: logoSize,
              objectFit: "cover", // Logonun boyutunu koruması için
            }}
          />
        )}
      </div>
    );
  }
);

export default ThemeQRCode;
