import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PrinterModel } from "../../../services/printers/models/printer.model";
import { Col, Row } from "antd";
import CompanyMngrPrinterAdd from "./CompanyMngrPrinterAdd";
import { PrinterService } from "../../../services/printers/printer.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import Title from "antd/es/typography/Title";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import ThemeDeleteButton from "@theme/ui-components/buttons/ThemeDeleteButton";
import { transactionDate } from "../../../funcs/func";
import {
  ColumnType,
  ThemeDataTable,
  ThemeDataTableSearch,
} from "@theme/ui-components/data-tables";
import { DataTableFilterMeta } from "primereact/datatable";
import ThemeDialog from "@theme/ui-components/dialogs/ThemeDialog";

const CompanyMngrPrinterList: React.FC = () => {
  const _printerService = useMemo(() => new PrinterService(), []);
  const companyId = useSelector(getCompanyId);
  const tempPrintList = useRef<PrinterModel[]>([]);
  const id = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<DataTableFilterMeta>({});
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState<PrinterModel[]>([]);
  const [count, setCount] = useState(0);

  const tableActions = (data: PrinterModel) => {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <ThemeButton
          loading={isLoading}
          disabled={isLoading}
          severity="success"
          type="button"
          onClick={() => {
            id.current = data.id;
            setModalVisible(true);
          }}
          icon={<i className="fa-duotone fa-pen-to-square"></i>}
        />
        <ThemeDeleteButton
          accept={async () => {
            setIsLoading(true);
            await _printerService.deleteById(data.id);
            await getPrinterList();
            setIsLoading(false);
          }}
          reject={async () => {
            setIsLoading(false);
            return Promise.resolve();
          }}
        />
      </div>
    );
  };

  const columnsT: ColumnType[] = [
    {
      field: "id",
      header: "Id",
    },
    {
      header: "Ad",
      field: "name",
    },
    {
      header: "İP Adresi",
      field: "printerIpAddress",
    },
    {
      header: "Port",
      field: "printerPort",
    },
    {
      field: "transactionDate",
      header: "İşlem Zamanı",
      body: transactionDate,
    },
    {
      field: "actions",
      header: "",
      body: tableActions,
    },
  ];

  const getPrinterList = useCallback(async () => {
    const response = await _printerService.getList(companyId);
    if (response) {
      setData(response);
      tempPrintList.current = response;
    }
  }, []);
  const initialLoad = useCallback(() => {
    getPrinterList();
  }, []);
  useEffect(() => {
    console.error(count);

    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initialLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [initialLoad, companyId]);

  return (
    <div>
      <Title level={3} className="my-title">
        Yazıcı Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <ThemeButton
            loading={isLoading}
            onClick={() => {
              id.current = 0;
              setModalVisible(true);
            }}
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
            label="Yeni Yazıcı Ekle"
          />
        </Col>
        <Col>
          <ThemeDataTableSearch filters={filters} setFilters={setFilters} />
        </Col>
      </Row>
      <ThemeDataTable
        value={data}
        filters={filters}
        columns={columnsT}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
      {modalVisible ? (
        <ThemeDialog
          onHide={async () => {
            id.current = 0;
            setModalVisible(false);
            setIsLoading(true);
            await getPrinterList();
            setIsLoading(false);
          }}
          visible={modalVisible}
        >
          <CompanyMngrPrinterAdd id={id.current} />
        </ThemeDialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyMngrPrinterList;
