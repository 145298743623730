import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getProductList } from "../../../../react-reduxs/slices/products/product_slice";
import { getCategoryList } from "../../../../react-reduxs/slices/categories/category_slice";
import WaiterCategoryList from "./WaiterCategoryList";
import "./waiter-product.css";
import WaiterProductAddToBasket from "./WaiterProductAddToBasket";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import ButtonPrinter from "../../../printers/ButtonPrinter";
import { getPrinterList } from "../../../../react-reduxs/slices/printers/printer_slice";
import { MenuProductModel } from "../../../../services/menu_products/models/menu_product.model";
import { classNames } from "primereact/utils";
import { getCompany } from "../../../../react-reduxs/slices/companies/company_slice";
import { convertToUnicode } from "../../../../funcs/func";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";

type WaiterProductListProps = {
  tableId: string;
};

const WaiterProductList: React.FC<WaiterProductListProps> = ({ tableId }) => {
  const company = useSelector(getCompany);
  const productList = useSelector(getProductList);
  const categoryList = useSelector(getCategoryList);
  const printerList = useSelector(getPrinterList);
  const [search, setSearch] = useState("");
  const [layout, setLayout] = useState<"grid" | "list">("grid");

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categoryList[0]?.id ?? 0
  );
  const onChangeSelectCategory = useCallback((id: number) => {
    setSelectedCategoryId(id);
  }, []);
  const listItem = (product: MenuProductModel, index: number) => {
    const printer = printerList.find((x) => x.id === product.printerId);
    return (
      <div className="col-12" key={product.id}>
        <div
          className={classNames(
            "flex flex-column xl:flex-row xl:align-items-start p-2 gap-2",
            { "border-top-1 surface-border": index !== 0 }
          )}
        >
          <img
            className="w-6 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
            src={product.photo?.toApiImage("product.png")}
            alt={product.title}
          />
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-2">
            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
              <div className="text-xl font-bold text-900">{product.title}</div>
              <div className="flex align-items-center gap-2">
                <span className="flex align-items-center gap-0">
                  <span className="text-md font-normal text-ellipsis">
                    {product.detail}
                  </span>
                </span>
              </div>
            </div>
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-2 sm:gap-1">
              <span className="text-xl font-semibold">
                {convertToUnicode(company?.currency?.symbol!)}
                {product.price}
              </span>
              {printer ? (
                <ButtonPrinter
                  ipAddress={printer.printerIpAddress}
                  port={parseInt(printer.printerPort ?? "0")}
                />
              ) : (
                <></>
              )}
              <WaiterProductAddToBasket product={product} tableId={tableId} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const gridItem = (product: MenuProductModel) => {
    const printer = printerList.find((x) => x.id === product.printerId);
    return (
      <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-1" key={product.id}>
        <div className="p-2 border-1 surface-border surface-card border-round">
          <div className="flex flex-wrap align-items-center justify-content-center gap-2 ">
            <div className="text-xl font-bold">{product.title}</div>
          </div>
          <div className="flex flex-column align-items-center gap-1 py-2">
            <img
              className="w-6 shadow-2 border-round img-size"
              height={190}
              src={product.photo?.toApiImage("product.png")}
              alt={product.title}
            />
            <div className="flex align-items-center gap-2">
              <span className="text-md font-normal text-ellipsis">
                {product.detail}
              </span>
            </div>
          </div>
          <div className="flex align-items-center justify-content-between">
            <span className="text-xl font-semibold">
              {convertToUnicode(company?.currency?.symbol!)} {product.price}
            </span>
            {printer ? (
              <ButtonPrinter
                ipAddress={printer.printerIpAddress}
                port={parseInt(printer.printerPort ?? "0")}
              />
            ) : (
              <></>
            )}
            <WaiterProductAddToBasket product={product} tableId={tableId} />
          </div>
        </div>
      </div>
    );
  };
  const itemTemplate = (
    product: MenuProductModel,
    layout: string,
    index: number
  ) => {
    if (!product) {
      return;
    }

    if (layout === "list") return listItem(product, index);
    else if (layout === "grid") return gridItem(product);
  };

  const listTemplate = (products: MenuProductModel[], layout: string) => {
    return (
      <div className="grid grid-nogutter">
        {products.map((product, index) => itemTemplate(product, layout, index))}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex justify-content-end">
        <DataViewLayoutOptions
          layout={layout}
          onChange={(e) => setLayout(e.value as "grid" | "list")}
        />
      </div>
    );
  };
  return (
    <div>
      <WaiterCategoryList
        selectedCategoryId={selectedCategoryId}
        onChangeSelectCategory={onChangeSelectCategory}
        categoryList={categoryList}
      />

      <IconField iconPosition="left" className="m-1">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          className="w-full"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Ara"
        />
      </IconField>
      <DataView
        value={productList
          .filter((x) => x.menuCategoryId === selectedCategoryId)
          .filter((x) =>
            x.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          )}
        listTemplate={(items, layout) =>
          listTemplate(items, layout as "grid" | "list")
        }
        layout={layout}
        header={header()}
      />
    </div>
  );
};

export default WaiterProductList;
