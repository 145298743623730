import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure_store";
import { TableModel } from "../../../services/tables/models/tabele.model";

interface TableState {
  tableId: string;
  table?: TableModel;
}

const initialState: TableState = {
  tableId: "",
};
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableId: (state, action: PayloadAction<string>) => {
      state.tableId = action.payload;
    },
    setTable: (state, action: PayloadAction<TableModel|undefined>) => {
      state.table = action.payload;
    },
  },
});

export const { setTableId, setTable } = tableSlice.actions;

export const getTableId = (state: RootState) => state.table.tableId;

export const getTable = (state: RootState) => state.table.table;

const TableReducer = tableSlice.reducer;
export default TableReducer;
