import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdminCompanyUserAdd from "../../admins/company/AdminCompanyUserAdd";
import { Col, Modal, Row } from "antd";
import { Provider, useSelector } from "react-redux";
import { store } from "../../../react-reduxs/configure_store";
import dayjs from "dayjs";
import { UserListModel } from "../../../services/users/models/user_list.model";
import Title from "antd/es/typography/Title";
import { UserService } from "../../../services/users/user.service";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { useTranslation } from "react-i18next";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import {
  ColumnType,
  ThemeDataTable,
  ThemeDataTableSearch,
} from "@theme/ui-components/data-tables";
import { DataTableFilterMeta } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import ThemeCheckbox from "@theme/ui-components/checkboxs/ThemeCheckbox";

const CompManagerUserList = () => {
  const companyId = useSelector(getCompanyId);
  const userService = useMemo(() => new UserService(), []);
  const [data, setData] = useState<UserListModel[]>([]);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const tempUserList = useRef<UserListModel[]>([]);

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    userName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const groupNameBody = (data: any) => {
    return <p>{t(`template.${data.groupName}`)}</p>;
  };
  const statusBody = (data: any) => {
    return (
      <ThemeCheckbox
        checked={data.deletedDate === undefined}
        onClick={async () => {
          if (data.deletedDate === undefined) {
            await userService.disableUser(data.id, dayjs().format());
            await getUserList();
          } else {
            await userService.disableUser(data.id, null);
            await getUserList();
          }
        }}
      />
    );
  };
  const transactionDate = (data: any) => {
    if (data.updatedDate) {
      return dayjs(data.updatedDate).format("DD/MM/YYYY HH:mm");
    }
    return dayjs(data.createdDate).format("DD/MM/YYYY HH:mm");
  };
  const columnsT: ColumnType[] = [
    {
      field: "id",
      header: "Id",
    },
    {
      field: "userName",
      header: "Kulanıcı Adı",
    },
    {
      field: "email",
      header: "E-Posta",
    },
    {
      field: "groupName",
      header: "Grup Adı",
      body: groupNameBody,
    },
    {
      field: "transactionDate",
      header: "İşlem Zamanı",
      body: transactionDate,
    },
    {
      field: "status",
      header: "Durum",
      body: statusBody,
    },
  ];

  const getUserList = useCallback(async () => {
    setIsLoading(true);
    let response = await userService.getListByCompanyId(companyId);

    if (response) {
      tempUserList.current = response;
      setData(response);
    }
    setIsLoading(false);
  }, [companyId, userService]);
  useEffect(() => {
    if (companyId !== 0) {
      console.info(count);
      setCount((cou) => {
        if (cou === 0) {
          getUserList();
        }
        return 1;
      });
    }

    return () => {};
  }, [companyId, getUserList]);

  return (
    <div>
      <Title level={3} className="my-title">
        Kullanıcı Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <ThemeButton
            label="Ekle"
            icon="pi pi-user-plus"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => {
              Modal.info({
                okType: "danger",
                okText: "İptal",

                content: (
                  <Provider store={store}>
                    <AdminCompanyUserAdd
                      isLoading={isLoading}
                      setIsloading={setIsLoading}
                      companyId={companyId}
                      getList={() => getUserList()}
                      compManager={true}
                    />
                  </Provider>
                ),
              });
            }}
          />
        </Col>
        <Col>
          <ThemeDataTableSearch filters={filters} setFilters={setFilters} />
        </Col>
      </Row>
      <ThemeDataTable className="mt-2" value={data} columns={columnsT} filters={filters} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} />
    </div>
  );
};

export default CompManagerUserList;
