export enum OrderHubEnum{
    placeOrder="PlaceOrder",
    orderProductStatusUpdate="OrderProductStatusUpdate",
    orderClose="OrderClose",
    waiterOrderList="waiterOrderList",
    receiveOrder="receiveOrder",
    /**
     * Siparişi tablodan masadan
     */
    removeOrderTable="RemoveOrderTable"
}