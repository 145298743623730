import axios, { AxiosInstance, AxiosResponse } from "axios";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

import { ApiUrl } from "../consts/api";
class BaseApiService {

  private readonly axiosInstance: AxiosInstance;
  public apiUrl: string = ApiUrl + "/api/";

  constructor(baseURL: string) {
    let url = localStorage.getItem("login_key");
    if (url) {
      this.apiUrl = url;
    }

    baseURL = this.apiUrl + baseURL;
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use((config) => {
      let pret = localStorage.getItem("persist:root");

      if (pret) {
        let pretObject = JSON.parse(pret);

        if (pretObject.auth) {
          let authReducerObject = JSON.parse(pretObject.auth);
          if (authReducerObject) {
            const token = authReducerObject.token;

            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          }
        }
      }
      return config;
    });
    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.error(error);
        try {
          if (error.code === "ERR_NETWORK") {
            toast.warn(error.message);
          }
        } catch {}
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/login");
       
          toast.error(error.response.data);
        } else if (error.response.status === 404) {
          toast.error(error.response.data);
          // Handle not found error
        } else if (
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          console.error(error);

          // Handle other client errors
          if (error.response.data.Title === "Validation error(s)") {
            toast.error(JSON.stringify(error.response.data.Errors));
          } else {
            toast.error(error.response.data);
          }
        } else if (
          error.response.status >= 500 &&
          error.response.status < 600
        ) {
          // Handle server errors
          toast.error("Server Error. Please try again later.");
        } else {
          toast.error(error.response.data);
        }
        return Promise.reject(new Error(error));
      }
    );
  }
  private handleResponse(response: AxiosResponse<any>): any {
    if (response.status === 401) {
      // Token expired or not provided, handle accordingly
    }
    if (response.status === 404) {
      // Handle not found error
    }
    if (response.status >= 400 && response.status < 500) {
      // Handle other client errors
    }
    if (response.status >= 500 && response.status < 600) {
      // Handle server errors
    }
    return response.data;
  }

  public async get<T>(url: string, config?: any): Promise<T | undefined> {
    try {
      let response = await this.axiosInstance.get<T>(url, config);
      return this.handleResponse(response);
    } catch (error: any) {
      try {
        toast.error(error.response.data);
      } catch (error: any) {
        toast.error(error);
      }
      return;
    }
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: any
  ): Promise<T | undefined> {
    try {
      const response = await this.axiosInstance.post<T>(url, data, config);
      return this.handleResponse(response);
    } catch (error: any) {
      return;
    }
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: any
  ): Promise<T | undefined> {
    try {
      const response = await this.axiosInstance.put<T>(url, data, config);
      return this.handleResponse(response);
    } catch (error: any) {
      return;
    }
  }

  public async delete<T>(url: string, config?: any): Promise<T | undefined> {
    try {
      const response = await this.axiosInstance.delete<T>(url, config);
      return this.handleResponse(response);
    } catch (error: any) {
      return;
    }
  }
}
export default BaseApiService;
