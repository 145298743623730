import { Card, CardProps } from "primereact/card";
import React from "react";
import "./theme-card.scss";
interface ThemeCardProps extends CardProps {
  severity?: "default" | "high" | "primary" | "danger" | "success";
  selected?: boolean;
  outlined?: boolean;
  color?: string;
}
const ThemeCard: React.FC<ThemeCardProps> = ({
  severity = "default",
  selected = false,
  outlined = true,
  color,
  ...rest
}) => {
  let themeCardClass = "theme-card-default";
  if (severity === "high") {
    if (selected) {
      themeCardClass = "theme-card-high-select";
    } else {
      themeCardClass = "theme-card-high";
    }
  } else if (severity === "primary") {
    themeCardClass = "theme-card-primary";
  } else if (severity === "danger") {
    if (outlined) {
      themeCardClass = "outline-error";
    } else {
      themeCardClass = "theme-card-error";
    }
  } else if (severity === "success") {
    if (outlined) {
      themeCardClass = "outline-success";
    } else {
      themeCardClass = "theme-card-success";
    }
  }

  const combinedClassName = `${themeCardClass} ${rest.className ?? ""}`.trim();
  return (
    <Card
      className={combinedClassName}
      {...rest}
      style={{ color: color, ...(rest.style ?? "") }}
    >
      {rest.children}
    </Card>
  );
};

export default ThemeCard;
