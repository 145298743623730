import { Column } from "primereact/column";
import {
  DataTable,
  DataTableBaseProps,
  DataTableValue,
  DataTableValueArray,
} from "primereact/datatable";
import React from "react";
import { ColumnType } from "./column.type";
import "./th-data-table.css";

interface ThemeDataTableProps<T extends DataTableValueArray>
  extends DataTableBaseProps<T> {
  columns: Array<ColumnType>;
}

const ThemeDataTable: React.FC<ThemeDataTableProps<DataTableValue[]>> = ({
  columns,
 
  ...rest
}) => {
  return (
    <div>
      <DataTable {...rest} >
        {columns.map((col) => (
          <Column
            filter={col.filter}
            key={col.field}
            field={col.field}
            header={col.header}
            body={col.body}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default ThemeDataTable;
