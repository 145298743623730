import React, { ReactNode } from "react";
import "./confirm.popup.scss";
import ThemeButton from "../buttons/ThemeButton";
type ThemeConfirmPopupContentProps = {
  children?: ReactNode;
  header?: ReactNode;
  headerLIcon?: ReactNode;
  footer?: ReactNode;
  acceptBtnRef?: React.RefObject<HTMLButtonElement>;
  acceptBtnLabel?: string;
  accept: () => void;
  rejectBtnRef?: React.RefObject<HTMLButtonElement>;
  rejectBtnLabel?: string;
  btnToolVisible?: boolean;
  reject: () => void;
};
const ThemeConfirmPopupContent: React.FC<ThemeConfirmPopupContentProps> = ({
  children,
  header,
  footer,
  acceptBtnRef,
  acceptBtnLabel,
  accept,
  rejectBtnRef,
  rejectBtnLabel,
  reject,
  btnToolVisible = true,
  headerLIcon,
}) => {
  return (
    <div className="confirm-popup-content">
      <div className="confirm-popup-header">
        {headerLIcon}
        {header}
      </div>
      {children}
      <div className="confirm-popup-footer">{footer}</div>
      {btnToolVisible ? (
        <div className="confirm-popup-footer">
          <ThemeButton
            ref={acceptBtnRef}
            label={acceptBtnLabel}
            onClick={() => accept()}
            className="p-button-sm p-button-outlined"
          />
          <ThemeButton
            ref={rejectBtnRef}
            label={rejectBtnLabel}
            outlined
            severity="danger"
            onClick={() => reject()}
            className="p-button-sm p-button-text"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ThemeConfirmPopupContent;
