import React, { useRef, useState } from "react";
import ThemeButton from "./ThemeButton";
import ThemeConfirmPopup from "../dialogs/ThemeConfirmPopup";
import { ButtonProps } from "primereact/button";

interface ThemeDeleteButtonProps extends ButtonProps {
  message?: string;
  accept?: () => Promise<void>;
  reject?: () => Promise<void>;
}
const ThemeDeleteButton: React.FC<ThemeDeleteButtonProps> = ({
  message = "Kayıdı silinecek onaylıyormusunuz ?",
  accept = async () => {},
  reject = async () => {},
  icon = "pi pi-trash",
  severity = "danger",
  ...rest
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <div>
      <ThemeConfirmPopup
        visible={isModalVisible}
        acceptLabel="Evet"
        rejectLabel="Hayır"
        style={{ color: "red",padding:5,fontSize:"small" }}
        target={btnRef.current!}
        onHide={() => {
          setIsModalVisible(false);
        }}
        message={message}
        accept={() =>
          accept().finally(() => {
            setIsModalVisible(false);
          })
        }
        reject={() => {
          reject().finally(() => {
            setIsModalVisible(false);
          });
        }}
      />
      <ThemeButton
        onClick={() => {
          setIsModalVisible(true);
        }}
        {...rest}
        ref={btnRef}
        icon={icon}
        severity={severity}
      />
    </div>
  );
};

export default ThemeDeleteButton;
