import React from "react";
import { MenuCategoryModel } from "../../../../services/menu_categories/models/menu_category.model";
import { TabPanel, TabView } from "primereact/tabview";
import ThemeButton from "@theme/ui-components/buttons/ThemeButton";
import './waiter-product.css'
interface WaiterCategoryListProps {
  selectedCategoryId: number;
  categoryList: MenuCategoryModel[];
  onChangeSelectCategory: (id: number) => void;
}

const WaiterCategoryList: React.FC<WaiterCategoryListProps> = ({
  categoryList,
  selectedCategoryId,
  onChangeSelectCategory,
}) => {
  return (
    <div>
      <TabView scrollable className="none-p-tabview">
        {categoryList.map((category) => {
          return (
            <TabPanel className="custom-tab-panel"
              key={category.name}
              header={
                <ThemeButton
                  link
                  key={category.id}
                  onClick={() => onChangeSelectCategory(category.id)}
                  label={category.name}
                />
              }
            />
          );
        })}
      </TabView>
    </div>
  );
};

export default WaiterCategoryList;
