import React from "react";
import { MenuProductModel } from "../../../../../services/menu_products/models/menu_product.model";
import "./t4-product-detail.scss";
import T4BasketAddBtnComponent from "./T4BasketAddBtnComponent";
import T4OrderAddBtnComponent from "./T4OrderAddBtnComponent";
import { MenuProductAmountType } from "../../../../../models/menu_product_amount_type";
type T4ProductDetailProps = {
  product: MenuProductModel;
  tableId: string;
  menuProductAmountList: MenuProductAmountType[];
  removeMenuProductAmount: (menuProductId: number) => void;
};
const T4ProductDetail: React.FC<T4ProductDetailProps> = ({
  product,
  tableId,
  menuProductAmountList,
  removeMenuProductAmount,
}) => {
  return (
    <div className="t4-detail-contain">
      <div className="t4-detail-content">
        <img
          width={"50%"}
          alt=""
          src={(product.photo ?? "").toApiImage("product.png")}
        />
        <p>{product.detail}</p>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
        <h6>
          <b>Kalori: </b>
          {product.calorie}
        </h6>
        <h6>
          <b>Hazırlanma Süresi: </b>
          {product.timeToGetReady}
        </h6>
        <h6>
          <b>Alerjen Bilgisi: </b>
          {product.timeToGetReady}
        </h6>
      </div>
      <div className="t4-detail-foot">
        <T4BasketAddBtnComponent
          menuProduct={product}
          menuProductAmountList={menuProductAmountList}
          removeMenuProductAmount={removeMenuProductAmount}
          tableId={tableId}
        />
        <T4OrderAddBtnComponent
          menuProduct={product}
          removeMenuProductAmount={removeMenuProductAmount}
          menuProductAmountList={menuProductAmountList}
          tableId={tableId}
        />
      </div>
    </div>
  );
};

export default T4ProductDetail;
