import dayjs from "dayjs";
import { NotificationSendDto } from "../services/notification_settings/models/notification-send.dto";

function convertToUnicode(value: string) {
  const unicodeValue = value.replace(/\\u[\dA-F]{4}/gi, (match) => {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
  });
  return unicodeValue;
}
function transactionDate(data: any) {
  if (data.updatedDate) {
    return dayjs(data.updatedDate).format("DD/MM/YYYY HH:mm");
  }
  return dayjs(data.createdDate).format("DD/MM/YYYY HH:mm");
}

const generateRandomColor = (): string => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const generateUniqueColors = (count: number): string[] => {
  const colors = new Set<string>();
  while (colors.size < count) {
    colors.add(generateRandomColor());
  }
  return Array.from(colors);
};

function getOrderNotificationTemplate(
  companyId: number,
  tableNumber: string,
  orderProductCount: number
): NotificationSendDto {
  return {
    companyId: companyId,
    body: `Masa ${tableNumber} için ${orderProductCount} ürün sipariş edildi. Detayları kontrol edin!`,
    title: "Yeni Şipariş!",
    image: "https://apimenu.qrmenuscan.com/restaurant2.png",
  };
}
export {
  generateRandomColor,
  generateUniqueColors,
  convertToUnicode,
  transactionDate,
  getOrderNotificationTemplate
};
